import { getAxiosClientWithToken } from './index';

export const getNotifications = () => {
    const restClient = getAxiosClientWithToken();
    return restClient.get('/company/notifications');
};

export const markingAsViewed = (id: number) => {
    const restClient = getAxiosClientWithToken();
    return restClient.patch('/company/notifications/' + id);
};
