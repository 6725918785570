import { Outlet, useLocation } from 'react-router-dom';
import React from 'react';
import Roles from 'Roles';
import { Skeleton } from 'antd';
import { authSelector } from 'app/slice/authSlice';
import { useAppSelector } from 'app/hooks';
import KisokuTemplateManagement from 'pages/Dashboard/KisokuTemplate/KisokuTemplateManagement';

interface Props extends Roles {
}

const KisokuTemplate = (props: Props) => {
    const { allowedRoles } = props;
    const { roleName, token, loading } = useAppSelector(authSelector);
    const { pathname } = useLocation();

    if (loading) {
        return <Skeleton active={true} />;
    }

    if (!allowedRoles?.includes(roleName) || !token) {
        return <div>Forbidden</div>;
    }

    if (pathname === '/dashboard/kisoku-template') {
        return <KisokuTemplateManagement />;
    }

    return <Outlet />;
};

export default KisokuTemplate;
