import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { RootState } from 'app/store';
import { getScheduledMeetings } from 'api/meeting';

/**
 * GET user's scheduled meetings
 **/
export const getUserScheduledMeetings = createAsyncThunk('users/meetings', async (data, {
    dispatch,
    rejectWithValue,
}) => {
    try {
        const response = await getScheduledMeetings();
        dispatch(setScheduledMeetings(response.data));
        return response.data;
    } catch (err) {
        // We got validation errors, let's return those so we can reference in our component and set form errors
        return rejectWithValue(false);
    }
});

export type Schedule = {
    schedule: string;
    specialist: string;
};

type MeetingState = {
    schedules: Schedule[];
    meetingsFromPublicSupportCount: number;
    meetingsFromWorkRegulationCount: number;
};

const initialState: MeetingState = {
    schedules: [] as Schedule[],
    meetingsFromPublicSupportCount: 0,
    meetingsFromWorkRegulationCount: 0,
};

export const meetingSlice = createSlice({
    name: 'meeting',
    initialState: initialState,
    reducers: {
        setScheduledMeetings: (state, { payload }) => {
            const { schedules, meetings_from_public_support_count, meetings_from_work_regulation_count } = payload.data;
            state.schedules = schedules;
            state.meetingsFromPublicSupportCount = meetings_from_public_support_count;
            state.meetingsFromWorkRegulationCount = meetings_from_work_regulation_count;
        },
    },
});

export const selectMeeting = (state: RootState) => state.meeting;

export const { setScheduledMeetings } = meetingSlice.actions;
