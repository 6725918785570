import { FLUSH, PAUSE, PERSIST, persistReducer, PURGE, REGISTER, REHYDRATE } from 'redux-persist';
import { combineReducers, configureStore } from '@reduxjs/toolkit';

import { applicationSlice } from 'app/slice/applicationSlice';
import { authSlice } from 'app/slice/authSlice';
import { companySlice } from 'app/slice/companySlice';
import { corporateSlice } from 'app/slice/corporateSlice';
import { dashboardSlice } from 'app/slice/dashboardSlice';
import { forgotPasswordSlice } from 'app/slice/forgotPasswordSlice';
import { fundApplicationSlice } from 'app/slice/fundApplicationSlice';
import { fundDetailsSlice } from 'app/slice/fundDetailsSlice';
import { fundRequirementsSlice } from 'app/slice/fundRequirementsSlice';
import { fundsListSlice } from 'app/slice/fundsListSlice';
import { inquireSlice } from 'app/slice/inquireSlice';
import { loaderSlice } from 'app/slice/loaderSlice';
import { loginSlice } from 'app/slice/loginSlice';
import { meetingSlice } from 'app/slice/meetingSlice';
import { notificationSlice } from 'app/slice/notificationSlice';
import { profileSlice } from 'app/slice/profileSlice';
import { registrationSlice } from 'app/slice/registrationSlice';
import { resourcesSlice } from 'app/slice/resourcesSlice';
import storage from 'redux-persist/lib/storage';
import { successModalSlice } from 'app/slice/successSlice';
import { departmentSlice } from 'app/slice/departmentSlice';
import { employeeSlice } from 'app/slice/employeeSlice';
import { branchSlice } from 'app/slice/branchSlice';
import { customerSlice } from 'app/slice/customerSlice';
import { koutekiMeetingSlice } from 'app/slice/koutekiSlice';
import { employeekoutekiMeetingSlice } from 'app/slice/employeeKoutekiSlice';
import { workRegulationSlice } from 'app/slice/workRegulationSlice';
import { kisokuMeetingSlice } from 'app/slice/kisokuSlice';
import { consultationSlice } from 'app/slice/consultationSlice';
import { importSlice } from 'app/slice/importSlice';
import { exportSlice } from 'app/slice/exportSlice';
import { systemNotificationSlice } from 'app/slice/systemNotificationSlice';
import { diagnosticHistorySlice } from 'app/slice/diagnosticHistorySlice';
import { searchQuerySlice } from 'app/slice/searchQuerySlice';
import { servicesSlice } from 'app/slice/serviceSlice';
import { kisokuTemplateSlice } from 'app/slice/kisokuTemplateSlice';

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['auth'],
    blacklist: ['auth'],
};

const authPersistConfig = {
    key: 'auth',
    storage,
    whitelist: ['email', 'name', 'uuid', 'token'],
};

const reducers = combineReducers({
    auth: persistReducer(authPersistConfig, authSlice.reducer),
    applications: applicationSlice.reducer,
    loader: loaderSlice.reducer,
    login: loginSlice.reducer,
    profile: profileSlice.reducer,
    successModal: successModalSlice.reducer,
    forgotPassword: forgotPasswordSlice.reducer,
    company: companySlice.reducer,
    registration: registrationSlice.reducer,
    resource: resourcesSlice.reducer,
    inquire: inquireSlice.reducer,
    fundsList: fundsListSlice.reducer,
    fundDetails: fundDetailsSlice.reducer,
    fundApplication: fundApplicationSlice.reducer,
    fundRequirements: fundRequirementsSlice.reducer,
    notification: notificationSlice.reducer,
    meeting: meetingSlice.reducer,
    dashboard: dashboardSlice.reducer,
    corporate: corporateSlice.reducer,
    department: departmentSlice.reducer,
    employee: employeeSlice.reducer,
    branch: branchSlice.reducer,
    customer: customerSlice.reducer,
    koutekiMeeting: koutekiMeetingSlice.reducer,
    employeeKoutekiMeeting: employeekoutekiMeetingSlice.reducer,
    workRegulation: workRegulationSlice.reducer,
    kisokuMeeting: kisokuMeetingSlice.reducer,
    consultation: consultationSlice.reducer,
    import: importSlice.reducer,
    export: exportSlice.reducer,
    systemNotification: systemNotificationSlice.reducer,
    diagnosticHistory: diagnosticHistorySlice.reducer,
    searchQuery: searchQuerySlice.reducer,
    services: servicesSlice.reducer,
    kisokuTemplate: kisokuTemplateSlice.reducer
});

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
