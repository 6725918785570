import { DownOutlined, LogoutOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Dropdown, Layout, Menu } from 'antd';
import { Content, Footer, Header } from 'antd/lib/layout/layout';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import React, { CSSProperties, useCallback, useEffect, useState } from 'react';
import { authSelector, logout } from 'app/slice/authSlice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import logo from 'assets/images/daidolife_logo_light.png';
import { useIsMobile, useStyle } from 'lib/utils';
import Sidebar from 'components/common/Sidebar';
import CustomModal from 'components/common/CustomModal';
import questionIcon from 'assets/images/icon_question.png';
import { resetBranchDetail, resetDepartmentDetail } from 'app/slice/departmentSlice';
import SystemNotification from 'components/common/SystemNotification';

const SUPER_ADMIN_ONLY = ['kanri-daido-superadmin'];
const ADMIN_AND_EMPLOYEE = ['kanri-daido-admin', 'kanri-daido-employee'];

const isWithRoles = (roleName: string, roleList: string[]) => {
    return roleList.includes(roleName);
};

type Props = {
    onOpenChangePasswordModal: () => void;
};

const downOutlinedStyle: CSSProperties = {
    lineHeight: '24px',
    float: 'right',
    fontSize: 14,
    margin: '0px 10px',
};

const menuStyle: CSSProperties = {
    minWidth: 180,
};

const DashboardLayout = (props: Props) => {
    const { onOpenChangePasswordModal } = props;
    useStyle('dashboard');

    const isMobile = useIsMobile();
    const { pathname } = useLocation();
    const { name, token, roleName, branch } = useAppSelector(authSelector);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [isCollapsed, setIsCollapsed] = useState(true);
    const onCloseCollapsed = useCallback(() => setIsCollapsed(true), []);

    const [isConfirmLogoutVisible, setIsConfirmLogoutVisible] = useState(false);
    const onCloseConfirmLogout = () => setIsConfirmLogoutVisible(false);
    const onOpenConfirmLogout = () => setIsConfirmLogoutVisible(true);

    const onLogout = () => {
        dispatch(logout());
        onCloseConfirmLogout();
        dispatch(resetBranchDetail());
        dispatch(resetDepartmentDetail());
        navigate('/');
    };

    const onClickShowProfile = () => {
        navigate('/dashboard/profile');
    };

    const menu = (
        <Menu style={menuStyle}>
            {isWithRoles(roleName, SUPER_ADMIN_ONLY) && (
                <Menu.Item onClick={onOpenChangePasswordModal}>
                    <div className='item-container'>
                        <UserOutlined />
                        <span className='item-text'>パスワード変更</span>
                    </div>
                </Menu.Item>
            )}

            {isWithRoles(roleName, ADMIN_AND_EMPLOYEE) && (
                <Menu.Item onClick={onClickShowProfile}>
                    <div className='item-container'>
                        <UserOutlined />
                        <span className='item-text'>プロフィール</span>
                    </div>
                </Menu.Item>
            )}

            <Menu.Item onClick={onOpenConfirmLogout}>
                <div className='item-container'>
                    <LogoutOutlined />
                    <span className='item-text'>ログアウト</span>
                </div>
            </Menu.Item>
        </Menu>
    );

    useEffect(() => {
        if (!token) {
            return navigate('/');
        }
    }, [token, pathname, navigate]);

    return (
        <Layout>
            <SystemNotification />
            <Header className='dashboard-navbar'>
                <div className='logo'>
                    <img src={logo} alt='logo' />
                    <span>公的支援オンラインサービス／就業規則診断オンラインサービス</span>
                </div>
                <div className='buttons'>
                    {token && (
                        <Dropdown overlay={menu} placement='bottomRight' trigger={['click']}>
                            <Button type='primary' className='button'>
                                {branch?.name || name}
                                <DownOutlined style={downOutlinedStyle} />
                            </Button>
                        </Dropdown>
                    )}
                </div>
            </Header>

            {!isMobile ? (
                <Layout className='dashboard-content'>
                    <Sidebar key={+isMobile} />
                    <Layout className='px-25px'>
                        <Content>
                            <Outlet />
                        </Content>
                    </Layout>
                </Layout>
            ) : (
                <Layout>
                    <Sidebar key={+isMobile} breakpoint='lg' collapsedWidth='0' collapsed={isCollapsed}
                             onCollapse={collapsed => setIsCollapsed(collapsed)}
                             onCloseCollapsed={onCloseCollapsed}
                    />
                    <Layout className='dashboard-content' onClick={onCloseCollapsed}>
                        <Content>
                            <Outlet />
                        </Content>
                    </Layout>
                </Layout>
            )}

            <CustomModal
                isVisible={isConfirmLogoutVisible}
                onClose={onCloseConfirmLogout}
                title='ログアウト'
                icon={questionIcon}
                text='ログアウトしてもよろしいですか'
                showCancelButton={true}
                cancelButtonText='いいえ'
                showConfirmButton={true}
                confirmButtonText='はい'
                confirmOnClick={onLogout}
                cancelOnClick={onCloseConfirmLogout}
            />

            <Footer className='dashboard-footer'>Copyright © 中小企業福祉事業団</Footer>
        </Layout>
    );
};

export default DashboardLayout;
