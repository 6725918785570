import { getAxiosClientWithToken } from './index';

export const importBranchApi = (data: File) => {
    const formData = new FormData();
    formData.append('file', data);

    const restClient = getAxiosClientWithToken();
    return restClient.post('/import/branch', formData, {
        headers: {
            'Cache-Control': 'no-cache',
            'Content-Type': 'multipart/form-data',
        },
    });
};

export const importDaidoEmployeeApi = (data: File) => {
    const formData = new FormData();
    formData.append('file', data);

    const restClient = getAxiosClientWithToken();
    return restClient.post('/import/daido-employee', formData, {
        headers: {
            'Cache-Control': 'no-cache',
            'Content-Type': 'multipart/form-data',
        },
    });
};
