import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { getErrorMessage } from 'api';
import { fetchFundDetails } from 'api/funds';

export type FundDetailsState = {
    type: string,
    success: boolean | null,
    message: string,
    loading: boolean,
    data: FundDetailsResponse
}

export type FundDetailsRequest = {
    coverage_type?: string,
    type?: string,
    capital_range?: string,
    employee_count_range?: string,
    industry?: string,
    prefecture?: string,
    years_established_range?: string,
    page?: string,
    per_page?: string
}

export type FundDetailsResponse = {
    code: string,
    url: string,
    type: number,
    jurisdiction: string,
    title: string,
    overview: string,
    target_groups: string,
    amount: string,
    application_period: string,
    created_at: string,
    updated_at: string,
    label: string,
    fund_requirements: {
        title: string,
        requirements: { content: string }[]
    }[]
}

export const getFundDetails = createAsyncThunk(
    'funds/details',
    async (code: string, { rejectWithValue }) => {
        try {
            let response = await fetchFundDetails(code);

            if (response.data.success === true) {
                return response.data.data;
            }

            return rejectWithValue('Server error.');
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    },
);

export const fundDetailsSlice = createSlice({
    name: 'fundDetails',
    initialState: {
        type: '',
        success: null,
        message: '',
        loading: true,
        data: {},
    } as FundDetailsState,
    reducers: {},
    extraReducers: (builder) => {
        // getFundDetails action pending
        builder.addCase(getFundDetails.pending, (state: FundDetailsState) => {
            state.loading = true;
        });
        // getFundDetails action rejected
        builder.addCase(getFundDetails.rejected, (state: FundDetailsState, action) => {
            state.loading = false;
            state.success = false;
            state.message = action.payload as string;
        });
        // getFundDetails action fulfilled
        builder.addCase(getFundDetails.fulfilled, (state: FundDetailsState, action) => {
            state.loading = false;
            state.success = true;
            state.data = action.payload as FundDetailsResponse;
        });
    },
});

export const selectFundDetails = (state: RootState) => state.fundDetails;
