import { getAxiosClientWithToken } from 'api';
import { ExportApplicationRequest, ExportCompanyRequest, ExportUserRequest } from 'app/slice/exportSlice';

export const downloadDaidoEmployeeListApi = (params: ExportUserRequest) => {
    const { finish } = params;
    const restClient = getAxiosClientWithToken();
    return restClient.get('/daido/export/download/daidoEmployees', {
        params: { finish },
        responseType: 'arraybuffer',
    });
};

export const downloadCorporateListApi = (params: ExportCompanyRequest) => {
    const { start, finish } = params;
    const restClient = getAxiosClientWithToken();
    return restClient.get('/daido/export/download/companies', {
        params: { start, finish },
        responseType: 'arraybuffer',
    });
};

export const downloadKoutekiApi = (params: ExportApplicationRequest) => {
    const { from, to } = params;
    const restClient = getAxiosClientWithToken();
    return restClient.get(`/daido/export/download/applications/1`, {
        params: { from: from, to: to },
        responseType: 'arraybuffer',
    });
};

export const downloadKisokuApi = (params: ExportApplicationRequest) => {
    const { from, to } = params;
    const restClient = getAxiosClientWithToken();
    return restClient.get(`/daido/export/download/applications/2`, {
        params: { from: from, to: to },
        responseType: 'arraybuffer',
    });
};

export const getDaidoEmployeeApi = (params: ExportUserRequest) => {
    const { finish, page } = params;
    const restClient = getAxiosClientWithToken();
    return restClient.get('/daido/export/employees', {
        params: { finish, page },
    });
};

export const getCompaniesApi = (params: ExportCompanyRequest) => {
    const { start, finish, page } = params;
    const restClient = getAxiosClientWithToken();
    return restClient.get('/daido/export/companies', {
        params: { start, finish, page },
    });
};

export const getKoutekiApi = (params: ExportApplicationRequest) => {
    const { from, to, page } = params;
    const restClient = getAxiosClientWithToken();
    return restClient.get('/daido/export/applications/1', {
        params: { from: from, to: to, page },
    });
};

export const getKisokuApi = (params: ExportApplicationRequest) => {
    const { from, to, page } = params;
    const restClient = getAxiosClientWithToken();
    return restClient.get('/daido/export/applications/2', {
        params: { from: from, to: to, page },
    });
};
