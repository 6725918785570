import {createAsyncThunk, createSlice, isAnyOf} from '@reduxjs/toolkit';
import {RootState} from 'app/store';
import {getErrorMessage} from 'api';
import {
    deleteEmployee,
    deleteEmployeeBySupperAdmin,
    fetchRegisterEmployeeData,
    getBranches,
    getCompanyOfEmployee,
    getCompanyOfEmployeeBySuperAdmin,
    getDepartmentByBranch,
    getEmployeeAssignByEmployeeAndBranch,
    getEmployeeAssignByEmployeeAndBranchBySuperAdmin,
    getEmployeesDetail,
    registerEmployee,
    registerEmployeeOneByOne,
    transferBranchEmployee,
    transferBranchEmployeeBySuperAdmin,
    updateEmployee,
    updateEmployeeBySuperAdmin
} from "api/employee";
import {
    BasicDepartment,
    BranchData,
    DeleteEmployeeRequest,
    DepartmentByBranchRequest,
    EmployeeAssignRequest,
    EmployeeDetailRequest, FetchingRegisterEmployeeRequest,
    FormDataUpdateEmployee,
    FormDataUpdateEmployeeBySuperAdmin,
    GetBranchRequest,
    ReassignCompanyEmployeeRequest, RegisterEmployeeOneByOneData,
    RegisterEmployeeOneByOneRequest,
    TransferEmployeeRequest
} from "types";
import {Employee} from "app/slice/corporateSlice";
import {setDepartmentDetail} from "app/slice/departmentSlice";

export type EmployeeData = {
    detail: DetailEmployeeState,
    update: UpdateEmployeeState,
    branches: BranchesState,
    departments: DepartmentState,
    transferBranch: Request,
    delete: Request,
    reassignCompanies: ReassignCompanyState[],
    companies: ReassignCompanyState[],
    register: Request,
    registerOneByOne: Request,
    registerData: RegisterEmployeeState,
};

type RegisterEmployeeState = {
    success: boolean,
    loading: boolean,
    error: boolean,
    employee: RegisterEmployee,
}

type RegisterEmployee = {
    email: string,
    branchCode: string,
    branchName: string,
    departmentCode: string,
    departmentName: string,
}

type RegisterEmployeeData = {
    email: string,
    branch_code: string,
    branch_name: string,
    department_code: string,
    department_name: string,
}

interface Request {
    success: boolean;
    message: string | object;
    loading: boolean;
    error: boolean;
}

type DetailEmployeeData = {
    branchCode: string,
    branchName: string,
    departmentCode: string,
    departmentName: string,
    employeeCode: string,
    employeeLastName: string,
    employeeFirstName: string,
    emailAddress: string,
};

type DetailEmployeeState = DetailEmployeeData & Request;

interface UpdateEmployeeState extends Request {
    employee: Employee,
}

interface BranchesState extends Request {
    branches: BranchData[],
}

interface DepartmentState extends Request {
    departments: BasicDepartment[],
}

export interface Company {
    id: number,
    name: string,
}

export type EmployeeAssign = {
    code: string,
    firstname: string,
    lastname: string,
}

export interface ReassignCompanyState {
    id: number,
    company: Company,
    branches?: BranchData[],
    employees?: EmployeeAssign[],
}

export const fetchEmployeeDetail = createAsyncThunk('daido/employees/detail',
    async ({employeeCode}: EmployeeDetailRequest, {dispatch, rejectWithValue}) => {
        try {
            let response = await getEmployeesDetail({employeeCode});
            const {data = {}, success} = response.data;
            if (success) {
                dispatch(setEmployeeDetail(data));
                return true;
            }

            return rejectWithValue('Server error.');
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    });

export const asyncUpdateEmployee = createAsyncThunk('daido/employees/update',
    async (params: FormDataUpdateEmployee, {dispatch, rejectWithValue}) => {
        try {
            const response = await updateEmployee({
                employee_code: params.employeeCode,
                current_employee_code: params.currentEmployeeCode,
                branch_name: params.branchName,
                branch_code: params.branchCode,
                department_name: params.departmentName,
                department_code: params.departmentCode,
                email: params.email + params.emailExtension,
                lastname: params.lastname,
                firstname: params.firstname,
            });
            const {data = {}, success} = response.data;
            if (success) {
                dispatch(setEmployeeUpdate(data));
                return true;
            }

            return rejectWithValue('Server error.');
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    });

export const asyncUpdateEmployeeBySuperAdmin = createAsyncThunk('sp-admin/daido/employees/update',
    async (params: FormDataUpdateEmployeeBySuperAdmin, {dispatch, rejectWithValue}) => {
        try {
            const response = await updateEmployeeBySuperAdmin({
                employee_code: params.employeeCode,
                current_employee_code: params.currentEmployeeCode,
                branch_name: params.branchName,
                branch_code: params.branchCode,
                department_name: params.departmentName,
                department_code: params.departmentCode,
                email: params.email + params.emailExtension,
                employee_last_name: params.employeeLastName,
                employee_first_name: params.employeeFirstName,
            });
            const {data = {}, success} = response.data;
            if (success) {
                dispatch(setEmployeeUpdate(data));
                return true;
            }

            return rejectWithValue('Server error.');
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    });

export const fetchBranches = createAsyncThunk('daido/branches',
    async (params: GetBranchRequest, {dispatch, rejectWithValue}) => {
        try {
            const response = await getBranches(params);
            const {data = [], success} = response.data;
            if (success) {
                dispatch(setBranches(data));
                dispatch(setBranchReassign(data));
                return true;
            }

            return rejectWithValue('Server error.');
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    });

export const fetchDepartmentsByBranch = createAsyncThunk('daido/employee/departments/branches',
    async (params: DepartmentByBranchRequest, {dispatch, rejectWithValue}) => {
        try {
            const response = await getDepartmentByBranch(params);
            const {data = {}, success} = response.data;
            if (!params.departmentCode && success) {
                dispatch(setDepartments(data));
                return true;
            } else if (params.departmentCode && success) {
                dispatch(setDepartmentDetail(data));
                return true;
            }

            return rejectWithValue('Server error.');
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    });

export const asyncTransferBranchEmployee = createAsyncThunk('daido/employee/transfer-branch',
    async (params: TransferEmployeeRequest, {dispatch, rejectWithValue}) => {
        try {
            const response = await transferBranchEmployee(params);
            const {success} = response.data;
            if (success) {
                return true;
            }

            return rejectWithValue('Server error.');
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    });

export const asyncTransferBranchEmployeeBySuperAdmin = createAsyncThunk('sp-admin/daido/employee/transfer-branch',
    async (params: TransferEmployeeRequest, {dispatch, rejectWithValue}) => {
        try {
            const response = await transferBranchEmployeeBySuperAdmin(params);
            const {success} = response.data;
            if (success) {
                return true;
            }

            return rejectWithValue('Server error.');
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    });

export const asyncDeleteEmployee = createAsyncThunk('admin/daido/employee/delete',
    async (params: DeleteEmployeeRequest, {dispatch, rejectWithValue}) => {
        try {
            const requests = {
                employeeCode: params.employeeCode,
                reassignCompanies: params.reassignCompanies?.map(reassignCompany => ({
                    company_id: reassignCompany.companyId,
                    daido_branch_code_new: reassignCompany.daidoBranchCodeNew,
                    daido_employee_code_new: reassignCompany.daidoEmployeeCodeNew,
                }))
            } as ReassignCompanyEmployeeRequest;
            const response = await deleteEmployee(requests);
            const {success} = response.data;
            if (success) {
                return true;
            }

            return rejectWithValue('Server error.');
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    });

export const asyncDeleteEmployeeBySuperAdmin = createAsyncThunk('super-admin/daido/employee/delete',
    async (params: DeleteEmployeeRequest, {dispatch, rejectWithValue}) => {
        try {
            const requests = {
                employeeCode: params.employeeCode,
                reassignCompanies: params.reassignCompanies?.map(reassignCompany => ({
                    company_id: reassignCompany.companyId,
                    daido_branch_code_new: reassignCompany.daidoBranchCodeNew,
                    daido_employee_code_new: reassignCompany.daidoEmployeeCodeNew,
                }))
            } as ReassignCompanyEmployeeRequest;
            const response = await deleteEmployeeBySupperAdmin(requests);
            const {success} = response.data;
            if (success) {
                return true;
            }

            return rejectWithValue('Server error.');
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    });

export const fetchCompanyOfEmployee = createAsyncThunk('daido/employee/company',
    async ({employeeCode}: EmployeeDetailRequest, {dispatch, rejectWithValue}) => {
        try {
            const response = await getCompanyOfEmployee({employeeCode});
            const {data = [], success} = response.data;
            if (success) {
                dispatch(setCompanyReassign(data));
                return true;
            }

            dispatch(resetCompanyReassign());
            return rejectWithValue('Server error.');
        } catch (error: any) {
            dispatch(resetCompanyReassign());
            return rejectWithValue(getErrorMessage(error));
        }
    });

export const fetchCompanyOfEmployeeBySuperAdmin = createAsyncThunk('sp-admin/daido/employee/company',
    async ({employeeCode}: EmployeeDetailRequest, {dispatch, rejectWithValue}) => {
        try {
            const response = await getCompanyOfEmployeeBySuperAdmin({employeeCode});
            const {data = [], success} = response.data;
            if (success) {
                dispatch(setCompanyReassign(data));
                return true;
            }

            dispatch(resetCompanyReassign());
            return rejectWithValue('Server error.');
        } catch (error: any) {
            dispatch(resetCompanyReassign());
            return rejectWithValue(getErrorMessage(error));
        }
    });

export const fetchEmployeeAssign = createAsyncThunk('daido/employee/branch',
    async (params: EmployeeAssignRequest, {dispatch, rejectWithValue}) => {
        try {
            const response = await getEmployeeAssignByEmployeeAndBranch(params);
            const {data = [], success} = response.data;
            if (success) {
                dispatch(setEmployeeReassign({employees: data, companyId: params.companyId}));
                return true;
            }

            dispatch(setEmployeeReassign({employees: [], companyId: params.companyId}));
            return rejectWithValue('Server error.');
        } catch (error: any) {
            dispatch(setEmployeeReassign({employees: [], companyId: params.companyId}));
            return rejectWithValue(getErrorMessage(error));
        }
    });

export const fetchEmployeeAssignBySuperAdmin = createAsyncThunk('daido/sp-admin/employee/branch',
    async (params: EmployeeAssignRequest, {dispatch, rejectWithValue}) => {
        try {
            const response = await getEmployeeAssignByEmployeeAndBranchBySuperAdmin(params);
            const {data = [], success} = response.data;
            if (success) {
                dispatch(setEmployeeReassign({employees: data, companyId: params.companyId}));
                return true;
            }

            dispatch(setEmployeeReassign({employees: [], companyId: params.companyId}));
            return rejectWithValue('Server error.');
        } catch (error: any) {
            dispatch(setEmployeeReassign({employees: [], companyId: params.companyId}));
            return rejectWithValue(getErrorMessage(error));
        }
    });

export const asyncRegisterEmployee = createAsyncThunk('daido/employee/register',
    async (params: RegisterEmployeeOneByOneData, {dispatch, rejectWithValue}) => {
        try {
            const response = await registerEmployee({
                email: params.email + params.emailExtension,
                branch_code: params.branchCode,
                branch_name: params.branchName,
                department_code: params.departmentCode,
                department_name: params.departmentName,
                employee_code: params.employeeCode,
                employee_code_first_name: params.employeeCodeFirstName,
                employee_code_last_name: params.employeeCodeLastName,
                password: params.password,
                password_confirmation: params.passwordConfirmation,
            });
            const {success} = response.data;
            if (success) {
                return true;
            }

            return rejectWithValue('Server error.');
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    });

export const asyncRegisterEmployeeOneByOne = createAsyncThunk('daido/employee/register/one-by-one',
    async (params: RegisterEmployeeOneByOneRequest, {dispatch, rejectWithValue}) => {
        try {
            const response = await registerEmployeeOneByOne({
                department_code: params.departmentCode,
                department_name: params.departmentName,
                branch_code: params.branchCode,
                branch_name: params.branchName,
                email: params.email + params.emailExtension,
                employee_code: params.employeeCode,
            });
            const {success} = response.data;
            if (success) {
                return true;
            }

            return rejectWithValue('Server error.');
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    });

export const fetchDataRegisterEmployee = createAsyncThunk('daido/employee/registration',
async (params: FetchingRegisterEmployeeRequest, {dispatch, rejectWithValue}) => {
    try {
        const response = await fetchRegisterEmployeeData(params);
        const {data = {}, success = false} = response.data;
        if (success) {
            dispatch(setRegisterEmployeeData(data));
            return true;
        }
    } catch (error: any) {
        //
    }
    return rejectWithValue(false);
});

export const employeeSlice = createSlice({
    name: 'employee',
    initialState: {
        detail: {
            success: false,
            message: '',
            loading: false,
            error: false,
        },
        update: {
            success: false,
            message: '',
            loading: false,
            error: false,
        },
        branches: {
            success: false,
            message: '',
            loading: false,
            error: false,
        },
        departments: {
            success: false,
            message: '',
            loading: false,
            error: false,
        },
        transferBranch: {
            success: false,
            message: '',
            loading: false,
            error: false,
        },
        delete: {
            success: false,
            message: '',
            loading: false,
            error: false,
        },
        register: {
            success: false,
            message: '',
            loading: false,
            error: false,
        },
        registerOneByOne: {
            success: false,
            message: '',
            loading: false,
            error: false,
        },
        registerData: {
            success: false,
            loading: false,
            error: false,
        }
    } as EmployeeData,
    reducers: {
        setEmployeeDetail: (state: EmployeeData, action) => {
            const {user = {}, code: employeeCode, daido_department: department = {}} = action.payload;
            state.detail = {
                ...state.detail,
                branchCode: department?.daido_branch?.code,
                branchName: department?.daido_branch?.name,
                departmentCode: department?.code,
                departmentName: department?.name,
                employeeCode: employeeCode,
                employeeLastName: user?.profile?.lastname,
                employeeFirstName: user?.profile?.firstname,
                emailAddress: user?.email,
            };
        },
        setEmployeeUpdate: (state: EmployeeData, action) => {
            const {
                user = {},
                code: employeeCode,
                daido_department: department = {},
                number_of_corps_users_managed: numberOfCorpUsersManaged
            } = action.payload;
            state.update = {
                ...state.update,
                employee: {
                    numberOfCorpUsersManaged,
                    daidoBranchCode: department?.daido_branch?.code,
                    daidoBranchName: department?.daido_branch?.name,
                    departmentCode: department?.code,
                    departmentName: department?.name,
                    employeeCode: employeeCode,
                    employeeName: user?.profile?.lastname + ' ' + user?.profile?.firstname,
                    email: user?.email,
                }
            };
        },
        setBranches: (state: EmployeeData, action) => {
            state.branches = {
                ...state.branches,
                branches: action.payload as BranchData[],
            };
        },
        setDepartments: (state: EmployeeData, action) => {
            state.departments = {
                ...state.departments,
                departments: action.payload as BasicDepartment[],
            };
        },
        resetDepartmentState: (state: EmployeeData) => {
            state.departments = {
                success: false,
                message: '',
                loading: false,
                error: false,
                departments: [],
            };
        },
        setBranchReassign: (state: EmployeeData, action) => {
            const {reassignCompanies: newCompanies = []} = state;
            const branches = (action.payload ?? []) as BranchData[];
            state.reassignCompanies = newCompanies.map(company => {
                return {
                    ...company,
                    branches: branches,
                };
            })
        },
        setCompanyReassign: (state: EmployeeData, action) => {
            const {reassignCompanies: newCompanies = [], branches: {branches: newBranches = []}} = state;
            const companies = (action.payload ?? []) as Company[];
            state.reassignCompanies = companies.map(company => {
                const currentCompany = newCompanies.find(({company: customer}: ReassignCompanyState) => customer.id === company.id) as ReassignCompanyState;
                return {
                    id: company.id,
                    company: company,
                    branches: newBranches,
                    employees: currentCompany?.employees ?? [],
                };
            });
            state.companies = state.reassignCompanies;
        },
        resetCompanyReassign: (state: EmployeeData) => {
            state.reassignCompanies = [];
            state.companies = [];
        },
        setEmployeeReassign: (state: EmployeeData, action) => {
            const {reassignCompanies: newCompanies = []} = state;
            const {employees, companyId} = (action.payload ?? {});
            state.reassignCompanies = newCompanies.map(company => {
                const isSome = company.company.id === companyId;
                return {
                    ...company,
                    employees: isSome ? employees : company.employees,
                };
            })
        },
        setRegisterEmployeeData: (state: EmployeeData, {payload}: {payload: RegisterEmployeeData}) => {
            state.registerData.employee = {
                email: payload.email,
                branchCode: payload.branch_code,
                branchName: payload.branch_name,
                departmentCode: payload.department_code,
                departmentName: payload.department_name
            };
        },
        resetUpdateState: (state: EmployeeData) => {
            state.update = {
                ...state.update,
                success: false,
                message: '',
                loading: false,
                error: false,
            };
        },
        resetTransferBranchState: (state: EmployeeData) => {
            state.transferBranch = {
                success: false,
                message: '',
                loading: false,
                error: false,
            };
        },
        resetDeleteState: (state: EmployeeData) => {
            state.delete = {
                success: false,
                message: '',
                loading: false,
                error: false,
            };
        },
        resetReassignCompaniesState: (state: EmployeeData) => {
            state.reassignCompanies = [];
        },
        resetRegisterState: (state: EmployeeData) => {
            state.register = {
                success: false,
                message: '',
                loading: false,
                error: false,
            };
        },
        resetRegisterOneByOneState: (state: EmployeeData) => {
            state.registerOneByOne = {
                success: false,
                message: '',
                loading: false,
                error: false,
            };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchEmployeeDetail.pending, (state: EmployeeData) => {
                state.detail = {
                    ...state.detail,
                    loading: true,
                };
            })
            .addCase(fetchEmployeeDetail.rejected, (state: EmployeeData, action) => {
                state.detail = {
                    ...state.detail,
                    loading: false,
                    success: false,
                    error: true,
                    message: action.payload as string,
                    branchCode: '',
                    branchName: '',
                    departmentCode: '',
                    departmentName: '',
                    employeeCode: '',
                    employeeLastName: '',
                    employeeFirstName: '',
                    emailAddress: '',
                };
            })
            .addCase(fetchEmployeeDetail.fulfilled, (state: EmployeeData) => {
                state.detail = {
                    ...state.detail,
                    loading: false,
                    success: true,
                };
            })
            .addCase(fetchBranches.pending, (state: EmployeeData) => {
                state.branches = {
                    ...state.branches,
                    loading: true,
                };
            })
            .addCase(fetchBranches.rejected, (state: EmployeeData, action) => {
                state.branches = {
                    ...state.branches,
                    loading: false,
                    success: false,
                    error: true,
                    message: action.payload as string,
                    branches: [] as BranchData[],
                };
            })
            .addCase(fetchBranches.fulfilled, (state: EmployeeData) => {
                state.branches = {
                    ...state.branches,
                    loading: false,
                    success: true,
                    message: '' as string,
                };
            })
            .addCase(fetchDepartmentsByBranch.pending, (state: EmployeeData) => {
                state.departments = {
                    ...state.departments,
                    loading: true,
                };
            })
            .addCase(fetchDepartmentsByBranch.rejected, (state: EmployeeData, action) => {
                state.departments = {
                    ...state.departments,
                    loading: false,
                    success: false,
                    error: true,
                    message: action.payload as string,
                    departments: [] as BasicDepartment[],
                };
            })
            .addCase(fetchDepartmentsByBranch.fulfilled, (state: EmployeeData) => {
                state.departments = {
                    ...state.departments,
                    loading: false,
                    success: true,
                };
            })
            .addCase(asyncRegisterEmployee.pending, (state: EmployeeData) => {
                state.register = {
                    ...state.register,
                    loading: true,
                };
            })
            .addCase(asyncRegisterEmployee.rejected, (state: EmployeeData, action) => {
                state.register = {
                    ...state.register,
                    loading: false,
                    success: false,
                    error: true,
                    message: action.payload as string | object,
                };
            })
            .addCase(asyncRegisterEmployee.fulfilled, (state: EmployeeData) => {
                state.register = {
                    ...state.register,
                    loading: false,
                    success: true,
                };
            })
            .addCase(asyncRegisterEmployeeOneByOne.pending, (state: EmployeeData) => {
                state.registerOneByOne = {
                    ...state.registerOneByOne,
                    loading: true,
                };
            })
            .addCase(asyncRegisterEmployeeOneByOne.rejected, (state: EmployeeData, action) => {
                state.registerOneByOne = {
                    ...state.registerOneByOne,
                    loading: false,
                    success: false,
                    error: true,
                    message: action.payload as string | object,
                };
            })
            .addCase(asyncRegisterEmployeeOneByOne.fulfilled, (state: EmployeeData) => {
                state.registerOneByOne = {
                    ...state.registerOneByOne,
                    loading: false,
                    success: true,
                };
            })
            .addCase(fetchDataRegisterEmployee.pending, (state: EmployeeData) => {
                state.registerData.loading = true;
            })
            .addCase(fetchDataRegisterEmployee.rejected, (state: EmployeeData) => {
                state.registerData.loading = false;
                state.registerData.success = false;
                state.registerData.error = true;
                state.registerData.employee = {} as RegisterEmployee;
            })
            .addCase(fetchDataRegisterEmployee.fulfilled, (state: EmployeeData) => {
                state.registerData.loading = false;
                state.registerData.success = true;
                state.registerData.error = false;
            })
            .addMatcher(isAnyOf(asyncTransferBranchEmployee.pending, asyncTransferBranchEmployeeBySuperAdmin.pending), (state: EmployeeData) => {
                state.transferBranch = {
                    ...state.transferBranch,
                    loading: true as boolean,
                };
            })
            .addMatcher(isAnyOf(asyncTransferBranchEmployee.rejected, asyncTransferBranchEmployeeBySuperAdmin.rejected), (state: EmployeeData, action) => {
                state.transferBranch = {
                    ...state.transferBranch,
                    loading: false,
                    success: false,
                    error: true,
                    message: action.payload as string,
                };
            })
            .addMatcher(isAnyOf(asyncTransferBranchEmployee.fulfilled, asyncTransferBranchEmployeeBySuperAdmin.fulfilled), (state: EmployeeData) => {
                state.transferBranch = {
                    ...state.transferBranch,
                    loading: false,
                    success: true,
                };
            })
            .addMatcher(isAnyOf(asyncUpdateEmployee.pending, asyncUpdateEmployeeBySuperAdmin.pending), (state: EmployeeData) => {
                state.update = {
                    ...state.update,
                    loading: true,
                };
            })
            .addMatcher(isAnyOf(asyncUpdateEmployee.rejected, asyncUpdateEmployeeBySuperAdmin.rejected), (state: EmployeeData, action) => {
                state.update = {
                    ...state.update,
                    loading: false,
                    success: false,
                    error: true,
                    message: action.payload as string | object,
                };
            })
            .addMatcher(isAnyOf(asyncUpdateEmployee.fulfilled, asyncUpdateEmployeeBySuperAdmin.fulfilled), (state: EmployeeData) => {
                state.update = {
                    ...state.update,
                    loading: false,
                    success: true,
                };
            })
            .addMatcher(isAnyOf(asyncDeleteEmployee.pending, asyncDeleteEmployeeBySuperAdmin.pending), (state: EmployeeData) => {
                state.delete = {
                    ...state.delete,
                    loading: true as boolean,
                };
            })
            .addMatcher(isAnyOf(asyncDeleteEmployee.rejected, asyncDeleteEmployeeBySuperAdmin.rejected), (state: EmployeeData, action) => {
                state.delete = {
                    ...state.delete,
                    loading: false,
                    success: false,
                    error: true,
                    message: action.payload as string | object,
                };
            })
            .addMatcher(isAnyOf(asyncDeleteEmployee.fulfilled, asyncDeleteEmployeeBySuperAdmin.fulfilled), (state: EmployeeData) => {
                state.delete = {
                    ...state.delete,
                    loading: false,
                    success: true,
                };
            });
    },
});

export const {
    setEmployeeDetail,
    setEmployeeUpdate,
    setBranches,
    setDepartments,
    setBranchReassign,
    setCompanyReassign,
    setEmployeeReassign,
    resetUpdateState,
    resetTransferBranchState,
    resetDeleteState,
    resetReassignCompaniesState,
    resetRegisterState,
    resetDepartmentState,
    resetRegisterOneByOneState,
    resetCompanyReassign,
    setRegisterEmployeeData,
} = employeeSlice.actions;
export const employeeSelector = (state: RootState) => state.employee;
