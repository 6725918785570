import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getProfile, saveProfile } from 'api/user';

import { ProfileFieldData } from 'types';
import { RootState } from 'app/store';
import { getErrorMessage } from 'api';

export const FETCH_PROFILE = 'FETCH_PROFILE';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';

export type ProfileData = {
    user: {
        email: string;
        uuid: string;
    },
    profile: {
        id: number;
        userId: number;
        lastname: string;
        firstname: string;
        lastnameFurigana: string;
        firstnameFurigana: string;
        phone: string;
        createdAt: string;
        updatedAt: string
    },
    company: {
        name: string;
        nameFurigana: string;
        postalCode: string;
        address: string;
        prefectureCode: string;
        buildingName: string;
        industryCode: string;
        representativePosition: string;
        employeeCountRangeCode: string;
        parttimeEmployeeCount: number;
        insurancePlans: string[],
        laborRegulationsCompliant: number;
        allowSharoushi: number;
        allowShindanshi: number;
        createdAt: string;
        updatedAt: string;
    },
    insurancePlans: InsurancePlan[];
};

type InsurancePlan = {
    code: string;
    name: string;
};

export type ProfileState = {
    type: string;
    success: boolean | null;
    data: ProfileData;
    loading: boolean;
};

/**
 * calls profile API
 **/
export const fetchProfile = createAsyncThunk(
    'users/fetchProfile',
    async (uuid: string, { dispatch, rejectWithValue }) => {
        try {
            let response = await getProfile(uuid);
            const { data = {} } = response;

            if (data.success === true) {
                dispatch(setData(data.data));
                return true;
            }

            return rejectWithValue('Server error.');
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    },
);

/**
 * PATCH user's registration data
 **/
export const updateProfile = createAsyncThunk('users/updateProfile', async (values: ProfileFieldData, {
    getState,
    rejectWithValue,
}) => {
    try {
        let uuid = (getState() as RootState).auth.uuid;
        let response = await saveProfile(uuid, values);
        const { data = {} } = response;

        if (data.success === true) {
            return true;
        }

        return rejectWithValue('Server error.');
    } catch (error: any) {
        return rejectWithValue(getErrorMessage(error));
    }
});

export const profileSlice = createSlice({
    name: 'profile',
    initialState: {
        type: '',
        loading: false,
        success: null,
        data: {},
    } as ProfileState,
    reducers: {
        setData: (state, action) => {
            state.data = {
                ...action.payload,
                profile: {
                    ...action.payload.profile,
                    userId: action.payload.profile.user_id,
                    lastnameFurigana: action.payload.profile.lastname_furigana,
                    firstnameFurigana: action.payload.profile.firstname_furigana,
                    createdAt: action.payload.profile.created_at,
                    updatedAt: action.payload.profile.updated_at,
                },
                company: {
                    ...action.payload.company,
                    nameFurigana: action.payload.company.name_furigana,
                    postalCode: action.payload.company.postal_code,
                    prefectureCode: action.payload.company.prefecture_code,
                    buildingName: action.payload.company.building_name,
                    industryCode: action.payload.company.industry_code,
                    representativePosition: action.payload.company.representative_position,
                    employeeCountRangeCode: action.payload.company.employee_count_range_code,
                    parttimeEmployeeCount: action.payload.company.parttime_employee_count,
                    insurancePlans: action.payload.company.insurance_plans,
                    laborRegulationsCompliant: action.payload.company.labor_regulations_compliant,
                    allowSharoushi: action.payload.company.allow_sharoushi,
                    allowShindanshi: action.payload.company.allow_shindanshi,
                    createdAt: action.payload.company.created_at,
                    updatedAt: action.payload.company.updated_at,
                },
                insurancePlans: action.payload.insurance_plans,
            };
        },
    },
    extraReducers: (builder) => {
        // fetchProfile action pending
        builder.addCase(fetchProfile.pending, (state: ProfileState) => {
            state.type = FETCH_PROFILE;
            state.loading = true;
            state.success = null;
        });
        // fetchProfile action rejected
        builder.addCase(fetchProfile.rejected, (state: ProfileState) => {
            state.type = FETCH_PROFILE;
            state.loading = false;
            state.success = false;
        });
        // fetchProfile action fulfilled
        builder.addCase(fetchProfile.fulfilled, (state: ProfileState) => {
            state.type = FETCH_PROFILE;
            state.loading = false;
            state.success = true;
        });
        // updateProfile action pending
        builder.addCase(updateProfile.pending, (state: ProfileState) => {
            state.type = UPDATE_PROFILE;
            state.loading = true;
            state.success = null;
        });
        // updateProfile action rejected
        builder.addCase(updateProfile.rejected, (state: ProfileState) => {
            state.type = UPDATE_PROFILE;
            state.loading = false;
            state.success = false;
        });
        // updateProfile action fulfilled
        builder.addCase(updateProfile.fulfilled, (state: ProfileState) => {
            state.type = UPDATE_PROFILE;
            state.loading = false;
            state.success = true;
        });
    },
});

export const { setData } = profileSlice.actions;
export const profileSelector = (state: RootState) => state.profile;
