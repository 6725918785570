import { getAxiosClient, getAxiosClientWithToken } from './index';

export const fetchPrefectures = () => {
    const restClient = getAxiosClient();
    return restClient.get('/resources/prefectures');
};

export const fetchIndustries = () => {
    const restClient = getAxiosClient();
    return restClient.get('/resources/industries');
};

export const fetchInsurancePlans = () => {
    const restClient = getAxiosClient();
    return restClient.get('/resources/insurance-plans');
};

export const fetchEmployeeCountRanges = () => {
    const restClient = getAxiosClient();
    return restClient.get('/resources/employee-count-ranges');
};

export const fetchCoverageTypes = () => {
    const restClient = getAxiosClient();
    return restClient.get('/resources/coverage-types');
};

export const fetchCapitalRanges = () => {
    const restClient = getAxiosClient();
    return restClient.get('/resources/capital-ranges');
};

export const fetchYearsEstablishedRanges = () => {
    const restClient = getAxiosClient();
    return restClient.get('/resources/years-established-ranges');
};

export const fetchPurposes = () => {
    const restClient = getAxiosClient();
    return restClient.get('/resources/purposes');
};

export const fetchBranches = () => {
    const restClient = getAxiosClientWithToken();
    return restClient.get('/daido/branches');
};

export const fetchBranchDepartments = (code: string) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get(`/daido/branches/${code}/departments`);
}
