import {createAsyncThunk, createSlice, isAnyOf} from '@reduxjs/toolkit';

import { RootState } from 'app/store';
import { getErrorMessage } from 'api';
import {getCustomersByAdmin, getCustomersByEmployee} from "api/customer";

export type CustomerData = {
    customer: CustomerState,
    pagination: Pagination
};

export interface CustomerState extends Request {
    customers: Customer[],
}  

export interface Customer {
    uuid: string,
    companyName: string,
    email: string,
    corpUserName: string,
    industryName: string,
    employeeCountRange: string,
    interviewCount: string,
    employeeName: string,
}

interface Request {
    success: boolean;
    message: string | object;
    loading: boolean;
    error: boolean;
}

export type Pagination = {
    total: number,
    perPage: number,
    currentPage: number,
    lastPage: number,
}

export type GetCustomerByAdminRequest = {
    keyword?: string,
    filter_type?: Filter,
    page?: number
}

export type Filter = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7;

type CustomerDataState = {
    id: string,
    company_name: string,
    email: string,
    corp_user_name: string,
    industry_name: string,
    employee_count_range: string,
    interview_count: string,
    employee_name: string,
}

export const fetchListOfCustomersByAdmin = createAsyncThunk('daido/customers/by/admin',
    async (params: GetCustomerByAdminRequest, { dispatch, rejectWithValue }) => {
    try {
        let response = await getCustomersByAdmin(params);
        const { data = [], success } = response.data;
        if (success && data.length) {
            dispatch(setCustomerData(data));
            dispatch(setPagination(response.data));
            return true;
        }

        return rejectWithValue('Server error.');
    } catch (error: any) {
        return rejectWithValue(getErrorMessage(error));
    }
});

export const fetchListOfCustomersByEmployee = createAsyncThunk('daido/customers/by/employee',
    async (params: GetCustomerByAdminRequest, { dispatch, rejectWithValue }) => {
    try {
        let response = await getCustomersByEmployee(params);
        const { data = [], success } = response.data;
        if (success) {
            dispatch(setCustomerData(data));
            dispatch(setPagination(response.data));
            return true;
        }

        return rejectWithValue('Server error.');
    } catch (error: any) {
        return rejectWithValue(getErrorMessage(error));
    }
});

export const customerSlice = createSlice({
    name: 'corporate',
    initialState: {
        customer: {
            success: false,
            message: '',
            loading: false,
            error: false,
        },
        pagination: {} as Pagination
    } as CustomerData,
    reducers: {
        setCustomerData: (state: CustomerData, {payload}) => {
            state.customer.customers = payload.map((customer: CustomerDataState) => {
                return {
                    uuid: customer.id,
                    companyName: customer.company_name,
                    email: customer.email,
                    corpUserName: customer.corp_user_name,
                    industryName: customer.industry_name,
                    employeeCountRange: customer.employee_count_range,
                    interviewCount: customer.interview_count,
                    employeeName: customer.employee_name
                } as Customer;
            }) as Customer[];
        },
        setPagination: (state, { payload }) => {
            state.pagination = {
                total: payload.total,
                perPage: payload.per_page,
                currentPage: payload.current_page,
                lastPage: payload.last_page,
            };
        },
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(isAnyOf(fetchListOfCustomersByAdmin.pending, fetchListOfCustomersByEmployee.pending), (state: CustomerData) => {
                state.customer = {
                    ...state.customer,
                    loading: true,
                };
            })
            .addMatcher(isAnyOf(fetchListOfCustomersByAdmin.rejected, fetchListOfCustomersByEmployee.rejected), (state: CustomerData, action) => {
                state.customer = {
                    ...state.customer,
                    loading: false,
                    success: false,
                    error: true,
                    message: action.payload as string,
                    customers: [],
                };
            })
            .addMatcher(isAnyOf(fetchListOfCustomersByAdmin.fulfilled, fetchListOfCustomersByEmployee.fulfilled), (state: CustomerData) => {
                state.customer = {
                    ...state.customer,
                    loading: false,
                    success: true,
                };
            });
    },
});

export const { 
    setCustomerData,
    setPagination
} = customerSlice.actions;

export const customerSelector = (state: RootState) => state.customer;
