import React, { useEffect, useState } from 'react';
import { Layout, Menu } from 'antd';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { useAppSelector } from 'app/hooks';
import { SiderProps } from 'antd/lib/layout';
import {
    AreaChartOutlined,
    AuditOutlined,
    CustomerServiceOutlined,
    FileTextOutlined,
    FolderOpenOutlined,
    GroupOutlined,
    LayoutOutlined,
    QuestionCircleOutlined,
    TeamOutlined,
} from '@ant-design/icons';
import { authSelector } from 'app/slice/authSlice';
import { EMPLOYEE } from 'config/constant';

const ALL_ROLES = ['kanri-daido-superadmin', 'kanri-daido-admin', 'kanri-daido-employee'];
const SUPER_ADMIN_ONLY = ['kanri-daido-superadmin'];
const SUPER_ADMIN_AND_ADMIN = ['kanri-daido-superadmin', 'kanri-daido-admin'];

const isWithRoles = (roleName: string, roleList: string[]) => {
    return roleList.includes(roleName);
};

const { Sider } = Layout;

type Props = SiderProps & {
    onCloseCollapsed?: () => void;
}

const Sidebar = (props: Props) => {
    const { breakpoint, collapsedWidth, collapsed, onCollapse, onCloseCollapsed } = props;

    const { pathname } = useLocation();
    const { roleName } = useAppSelector(authSelector);

    const [openKeys, setOpenKeys] = useState<string[]>([]);

    /**
     * Close other collapse when one was opened
     *
     * @param {string[]} keys
     */
    const onOpenChange = (keys: string[]) => {
        let newOpenKeys = [];
        let lastKey = keys.pop();

        if (lastKey) newOpenKeys.push(lastKey);

        setOpenKeys(newOpenKeys);
    };

    useEffect(() => {
        onCloseCollapsed?.();
    }, [onCloseCollapsed, pathname]);

    return (
        <Sider className='dashboard-sider'
               width={300}
               breakpoint={breakpoint}
               collapsedWidth={collapsedWidth}
               collapsed={collapsed}
               onCollapse={onCollapse}
        >
            <Menu key={pathname} mode='inline' defaultSelectedKeys={[pathname]} openKeys={openKeys}
                  onOpenChange={onOpenChange}
            >
                {isWithRoles(roleName, ALL_ROLES) && (
                    <Menu.Item key='/dashboard/management' icon={<LayoutOutlined />}>
                        <NavLink to='/dashboard/management' className=''>
                            管理画面
                        </NavLink>
                    </Menu.Item>
                )}

                {isWithRoles(roleName, SUPER_ADMIN_ONLY) && (
                    <>
                        <Menu.Item key='/dashboard/branch' icon={<AuditOutlined />}>
                            <Link to='/dashboard/branch'>支社管理</Link>
                        </Menu.Item>
                        <Menu.Item key='/dashboard/department' icon={<GroupOutlined />}>
                            <Link to='/dashboard/department'>機関管理</Link>
                        </Menu.Item>
                    </>
                )}

                {isWithRoles(roleName, SUPER_ADMIN_AND_ADMIN) && (
                    <>
                        <Menu.Item key='/dashboard/employee' icon={<TeamOutlined />}>
                            <Link to='/dashboard/employee'>担当者管理</Link>
                        </Menu.Item>
                    </>
                )}

                {isWithRoles(roleName, ALL_ROLES) && (
                    <>
                        <Menu.Item key='/dashboard/customer' icon={<FolderOpenOutlined />}>
                            <Link to='/dashboard/customer'>お客さま管理 </Link>
                        </Menu.Item>
                        <Menu.Item key='/dashboard/kouteki' icon={<CustomerServiceOutlined />}>
                            <Link to='/dashboard/kouteki'>公的支援サービス </Link>
                        </Menu.Item>
                        <Menu.Item key='/dashboard/kisoku' icon={<FileTextOutlined />}>
                            <Link to='/dashboard/kisoku'>就業規則診断サービス </Link>
                        </Menu.Item>
                        {/* Will restore in the future */}
                        {/*<Menu.Item key='/dashboard/service' icon={<QuestionCircleOutlined />}>*/}
                        {/*    <Link to='/dashboard/service'>サービスの使い方 </Link>*/}
                        {/*</Menu.Item>*/}
                    </>
                )}

                {isWithRoles(roleName, SUPER_ADMIN_AND_ADMIN) && (
                    <>
                        <Menu.Item key='/dashboard/export' icon={<AreaChartOutlined />}>
                            <Link to='/dashboard/export'>エクスポート</Link>
                        </Menu.Item>
                    </>
                )}

                {isWithRoles(roleName, [EMPLOYEE]) && (
                    <>
                        <Menu.Item key='/dashboard/services' icon={<QuestionCircleOutlined />}>
                            <Link to='/dashboard/services'>サービスの使い方</Link>
                        </Menu.Item>
                    </>
                )}
            </Menu>
        </Sider>
    );
};

export default Sidebar;
