import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { RootState } from 'app/store';
import { FetchKotekiMeetingRequest } from 'types';
import { getKoutekiMeetings } from 'api/kouteki';
import { getErrorMessage } from 'api';
import { Label } from 'app/slice/employeeKoutekiSlice';

export type KoutekiMeetingState = {
    loading: boolean,
    success: boolean,
    total: number,
    perPage: number,
    currentPage: number,
    lastPage: number,
    koutekiMeetings: KoutekiMeetingData[],
};

export type KoutekiMeetingData = {
    title?: string[],
    schedule?: string,
    status: number,
    corporateName: string,
    label: Label,
    corpUserId: string,
    uuid: string,
    createdAt: string,
}

type koutekiMeeting = {
    title?: string[],
    schedule?: string,
    status: number,
    corporate_name: string,
    label: Label,
    corp_user_id: string,
    uuid: string,
    created_at: string,
}

export const fetchKoutekiMeetings = createAsyncThunk('daido/kouteki/meeting',
    async (params: FetchKotekiMeetingRequest, { dispatch, rejectWithValue }) => {
        try {
            if (params.status === -1 || params.status === undefined) {
                delete params.status;
            }
            let response = await getKoutekiMeetings(params);
            const { data = [], pagination = {}, success } = response.data;
            if (success) {
                dispatch(setKoutekiMeetingsData({ data, pagination }));
                return true;
            }

            return rejectWithValue(false);
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    });

export const koutekiMeetingSlice = createSlice({
    name: 'kouteki-meetting',
    initialState: {
        loading: false,
        success: false,
        total: 0,
        perPage: 0,
        currentPage: 0,
        lastPage: 0,
        koutekiMeetings: [],
    } as KoutekiMeetingState,
    reducers: {
        setKoutekiMeetingsData: (state: KoutekiMeetingState, { payload }) => {
            state.total = payload.pagination.total;
            state.perPage = payload.pagination.per_page;
            state.currentPage = payload.pagination.current_page;
            state.lastPage = payload.pagination.last_page;
            state.koutekiMeetings = [...payload.data].map((record: koutekiMeeting) => ({
                title: record.title,
                schedule: record.schedule,
                status: record.status,
                corporateName: record.corporate_name,
                label: record.label,
                corpUserId: record.corp_user_id,
                uuid: record.uuid,
                createdAt: record.created_at,
            }));
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchKoutekiMeetings.pending, (state: KoutekiMeetingState) => {
            state.loading = true;
        });
        builder.addCase(fetchKoutekiMeetings.fulfilled, (state: KoutekiMeetingState) => {
            state.loading = false;
            state.success = true;
        });
        builder.addCase(fetchKoutekiMeetings.rejected, (state: KoutekiMeetingState) => {
            state.loading = false;
            state.success = false;
            state.total = 0;
            state.perPage = 0;
            state.currentPage = 0;
            state.lastPage = 0;
            state.koutekiMeetings = [];
        });
    },
});

export const koutekiMeetingSelector = (state: RootState) => state.koutekiMeeting;
export const { setKoutekiMeetingsData } = koutekiMeetingSlice.actions;
