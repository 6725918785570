import { getAxiosClient, getAxiosClientWithToken } from './index';
import { RegistrationFieldData } from 'app/slice/registrationSlice';
import qs from 'qs';
import { ProfileFieldData } from 'types';

export const registering = (url: string, data: RegistrationFieldData) => {
    const restClient = getAxiosClient();
    return restClient.patch(url, qs.stringify(data), {
        headers: {
            'Cache-Control': 'no-cache',
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
        },
    });
};

export const fetchUserRegistrationData = (uuid: string, expires: string, signature: string) => {
    const restClient = getAxiosClient();
    return restClient.get('/company/register/' + uuid, { params: { expires: expires, signature: signature } });
};

export const getUserData = () => {
    const restClient = getAxiosClientWithToken();
    return restClient.get('/daido/user');
};

export const getProfile = (uuid: string) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get('/company/users/' + uuid);
};

export const saveProfile = (uuid: string, data: ProfileFieldData) => {
    const restClient = getAxiosClientWithToken();

    return restClient.put('/company/users/' + uuid, qs.stringify(data), {
        headers: {
            'Cache-Control': 'no-cache',
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
        },
    });
};
