import { getAxiosClientWithToken } from './index';
import {GetCustomerByAdminRequest} from "../app/slice/customerSlice";

export const getCustomersByAdmin = (params?: GetCustomerByAdminRequest) => {
    const restClient = getAxiosClientWithToken();

    return restClient.get('/daido/companies', {params});
}

export const getCustomersByEmployee = (params?: GetCustomerByAdminRequest) => {
    const restClient = getAxiosClientWithToken();

    return restClient.get('/daido/employee/corporate-users', {params});
};

