import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { RootState } from 'app/store';
import { getErrorMessage } from 'api';
import {
    deleteDepartment,
    getBranchDetail,
    getDepartmentByFilter,
    getDepartmentDetail,
    getSuggestBranches,
    registerDepartment,
    updateDepartment,
} from 'api/department';
import {
    BranchData,
    BranchDetailRequest,
    DepartmentBranchQuery,
    DepartmentData,
    DepartmentDetailData,
    DepartmentDetailQuery,
    DepartmentQuery,
    RegisterDepartmentRequest,
    UpdateDepartmentFieldData,
} from 'types';
import { isEmpty } from 'lodash';

export type Pagination = {
    total: number,
    perPage: number,
    currentPage: number,
    lastPage: number,
}

export type DepartmentAndBranchData = {
    departments: DepartmentData[];
    branches: BranchData[];
    branchDetail: BranchDetailState;
    departmentDetail: DepartmentDetailSate;
    departmentRequest: Request,
    branchDepartmentRequest: Request,
    registerDepartment: RegisterState,
    updateDepartment: RegisterState,
    deleteDepartment: RegisterState,
    pagination: Pagination
};

export type Request = {
    isRequesting: boolean;
    isRequestSuccess: boolean;
    isRequestError: boolean;
    messageFromRequest: string;
}

export type RegisterState = {
    success: boolean | null;
    message: string | object;
    loading: boolean;
    error: boolean;
};

export type BranchDetailState = RegisterState & BranchData;

export type DepartmentDetailSate = RegisterState & DepartmentDetailData;

interface DepartmentItem extends DepartmentItemData {
    daido_departments: DepartmentItemData[];
}

interface DepartmentItemData {
    id: number,
    code: string,
    name: string,
}

export const fetchDepartmentByType = createAsyncThunk('daido/departments',
    async (params: DepartmentQuery, { dispatch, rejectWithValue }) => {
        try {
            let response = await getDepartmentByFilter(params);
            const { data = [], success } = response.data;
            if (success) {
                const departments = data.map((department: DepartmentItem) => ({
                    id: department.id,
                    code: department.code,
                    name: department.name,
                    daidoDepartments: department.daido_departments.map((item: DepartmentItemData) => ({
                        id: item.id,
                        code: item.code,
                        name: item.name,
                    })) as DepartmentItemData[],
                })) as DepartmentData[];
                dispatch(setDepartments(departments));
                dispatch(setPagination(response.data));
                return true;
            }

            return rejectWithValue('Server error.');
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    });

export const fetchDepartmentBranches = createAsyncThunk('daido/departments/branches',
    async (params: DepartmentBranchQuery, { dispatch, rejectWithValue }) => {
        try {
            let response = await getSuggestBranches(params);
            const { data = {}, success } = response.data;
            if (success) {
                dispatch(setDepartmentBranches(data));
                return true;
            }

            return rejectWithValue('Server error.');
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    });

export const asyncRegisterDepartment = createAsyncThunk('daido/departments/register',
    async (params: RegisterDepartmentRequest, { rejectWithValue }) => {
        try {
            let response = await registerDepartment({
                branch_code: params.branchCode,
                branch_name: params.branchName,
                department_name: params.departmentName,
                department_code: params.departmentCode,
            });
            const { success } = response.data;
            if (success) {
                return true;
            }

            return rejectWithValue('Server error.');
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    });

export const fetchBranchDetail = createAsyncThunk('daido/departments/branches/detail',
    async ({ branchCode }: BranchDetailRequest, { dispatch, rejectWithValue }) => {
        try {
            let response = await getBranchDetail({ branchCode });
            const { data = {}, success } = response.data;
            if (success && !isEmpty(data)) {
                dispatch(setBranchDetail(data));
                return true;
            }

            return rejectWithValue('Server error.');
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    });

export const fetchDepartmentDetail = createAsyncThunk('daido/departments/detail',
    async ({ departmentId }: DepartmentDetailQuery, { dispatch, rejectWithValue }) => {
        try {
            let response = await getDepartmentDetail({ departmentId });
            const { data = {}, success } = response.data;
            if (success) {
                dispatch(setDepartmentDetail(data));
                return true;
            }

            return rejectWithValue('Server error.');
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    });

export const asyncUpdateDepartment = createAsyncThunk('daido/departments/update',
    async (params: UpdateDepartmentFieldData, { rejectWithValue }) => {
        try {
            const response = await updateDepartment({
                id: params.id,
                department_name: params.departmentName,
                department_code: params.departmentCode,
                branch_code: params.branchCode,
            });
            const { success } = response.data;
            if (success) {
                return true;
            }

            return rejectWithValue('Server error.');
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    });

export const asyncDeleteDepartment = createAsyncThunk('daido/departments/delete',
    async (params: DepartmentDetailQuery, { rejectWithValue }) => {
        try {
            let response = await deleteDepartment(params);
            const { success } = response.data;
            if (success) {
                return true;
            }

            return rejectWithValue('機関が削除できません。');
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    });

export const departmentSlice = createSlice({
    name: 'departments',
    initialState: {
        departments: [],
        branches: [],
        branchDetail: {
            success: false,
            message: '',
            loading: false,
            error: false,
            code: '',
            name: '',
        },
        departmentDetail: {
            success: false,
            message: '',
            loading: false,
            error: false,
            code: '',
            name: '',
            daido_branch: {
                code: '',
                name: '',
            },
        },
        departmentRequest: {
            isRequesting: false,
            isRequestSuccess: false,
            isRequestError: false,
            messageFromRequest: '',
        },
        branchDepartmentRequest: {
            isRequesting: false,
            isRequestSuccess: false,
            isRequestError: false,
            messageFromRequest: '',
        },
        registerDepartment: {
            success: false,
            message: '',
            loading: false,
            error: false,
        },
        updateDepartment: {
            success: false,
            message: '',
            loading: false,
            error: false,
        },
        deleteDepartment: {
            success: false,
            message: '',
            loading: false,
            error: false,
        },
        pagination: {} as Pagination,
    } as DepartmentAndBranchData,
    reducers: {
        setDepartments: (state, action) => {
            state.departments = action?.payload ?? [];
        },
        setBranchDetail: (state, action) => {
            state.branchDetail = {
                ...state.branchDetail,
                ...action?.payload as BranchData,
            };
        },
        setDepartmentDetail: (state, action) => {
            state.departmentDetail = {
                ...state.departmentDetail,
                ...action?.payload as DepartmentDetailData,
            };
        },
        setDepartmentBranches: (state, action) => {
            state.branches = action?.payload ?? [];
        },
        setPagination: (state, { payload }) => {
            state.pagination = {
                total: payload.total,
                perPage: payload.per_page,
                currentPage: payload.current_page,
                lastPage: payload.last_page,
            };
        },
        resetDepartmentRequestState: (state) => {
            state.departmentRequest = {
                isRequestError: false,
                isRequestSuccess: false,
                isRequesting: false,
                messageFromRequest: '',
            };
        },
        resetDepartmentBranchRequestState: (state) => {
            state.branchDepartmentRequest = {
                isRequestError: false,
                isRequestSuccess: false,
                isRequesting: false,
                messageFromRequest: '',
            };
        },
        resetRegisterDepartmentRequestState: (state) => {
            state.registerDepartment = {
                success: false,
                message: '',
                loading: false,
                error: false,
            };
        },
        resetUpdateDepartmentRequestState: (state) => {
            state.updateDepartment = {
                success: false,
                message: '',
                loading: false,
                error: false,
            };
        },
        resetDeleteDepartmentRequestState: (state) => {
            state.deleteDepartment = {
                success: false,
                message: '',
                loading: false,
                error: false,
            };
        },
        resetBranchDetail: (state) => {
            state.branchDetail = {
                success: false,
                message: '',
                loading: false,
                error: false,
                code: '',
                name: '',
            };
        },
        resetDepartmentDetail: (state) => {
            state.departmentDetail = {
                success: false,
                message: '',
                loading: false,
                error: false,
                code: '',
                name: '',
                daido_branch: {
                    code: '',
                    name: '',
                },
            };
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchDepartmentByType.pending, (state: DepartmentAndBranchData) => {
            state.departmentRequest = {
                ...state.departmentRequest,
                isRequesting: true,
            };
        });
        builder.addCase(fetchDepartmentByType.rejected, (state: DepartmentAndBranchData, action) => {
            state.departmentRequest = {
                ...state.departmentRequest,
                isRequesting: false,
                isRequestSuccess: false,
                isRequestError: true,
                messageFromRequest: action.payload as string,
            };
        });
        builder.addCase(fetchDepartmentByType.fulfilled, (state: DepartmentAndBranchData) => {
            state.departmentRequest = {
                ...state.departmentRequest,
                isRequesting: false,
                isRequestSuccess: true,
            };
        });

        builder.addCase(fetchDepartmentBranches.pending, (state: DepartmentAndBranchData) => {
            state.branchDepartmentRequest = {
                ...state.branchDepartmentRequest,
                isRequesting: true,
            };
        });
        builder.addCase(fetchDepartmentBranches.rejected, (state: DepartmentAndBranchData, action) => {
            state.branchDepartmentRequest = {
                ...state.branchDepartmentRequest,
                isRequesting: false,
                isRequestSuccess: false,
                isRequestError: true,
                messageFromRequest: action.payload as string,
            };
        });
        builder.addCase(fetchDepartmentBranches.fulfilled, (state: DepartmentAndBranchData) => {
            state.branchDepartmentRequest = {
                ...state.branchDepartmentRequest,
                isRequesting: false,
                isRequestSuccess: true,
            };
        });

        builder.addCase(asyncRegisterDepartment.pending, (state: DepartmentAndBranchData) => {
            state.registerDepartment = {
                ...state.registerDepartment,
                loading: true,
            };
        });
        builder.addCase(asyncRegisterDepartment.rejected, (state: DepartmentAndBranchData, action) => {
            state.registerDepartment = {
                ...state.registerDepartment,
                loading: false,
                success: false,
                error: true,
                message: action.payload as string | object,
            };
        });
        builder.addCase(asyncRegisterDepartment.fulfilled, (state: DepartmentAndBranchData) => {
            state.registerDepartment = {
                ...state.registerDepartment,
                loading: false,
                success: true,
            };
        });

        builder.addCase(fetchBranchDetail.pending, (state: DepartmentAndBranchData) => {
            state.branchDetail = {
                ...state.branchDetail,
                loading: true,
            };
        });
        builder.addCase(fetchBranchDetail.rejected, (state: DepartmentAndBranchData, action) => {
            state.branchDetail = {
                ...state.branchDetail,
                loading: false,
                success: false,
                error: true,
                message: action.payload as string,
                name: '',
            };
        });
        builder.addCase(fetchBranchDetail.fulfilled, (state: DepartmentAndBranchData) => {
            state.branchDetail = {
                ...state.branchDetail,
                loading: false,
                success: true,
                error: false,
            };
        });

        builder.addCase(fetchDepartmentDetail.pending, (state: DepartmentAndBranchData) => {
            state.departmentDetail = {
                ...state.departmentDetail,
                loading: true,
            };
        });
        builder.addCase(fetchDepartmentDetail.rejected, (state: DepartmentAndBranchData, action) => {
            state.departmentDetail = {
                ...state.departmentDetail,
                loading: false,
                success: false,
                error: true,
                message: action.payload as string,
                name: '',
            };
        });
        builder.addCase(fetchDepartmentDetail.fulfilled, (state: DepartmentAndBranchData) => {
            state.departmentDetail = {
                ...state.departmentDetail,
                loading: false,
                success: true,
            };
        });

        builder.addCase(asyncUpdateDepartment.pending, (state: DepartmentAndBranchData) => {
            state.updateDepartment = {
                ...state.updateDepartment,
                loading: true,
                message: '',
            };
        });
        builder.addCase(asyncUpdateDepartment.rejected, (state: DepartmentAndBranchData, action) => {
            state.updateDepartment = {
                ...state.updateDepartment,
                loading: false,
                success: false,
                error: true,
                message: action.payload as string | object,
            };
        });
        builder.addCase(asyncUpdateDepartment.fulfilled, (state: DepartmentAndBranchData) => {
            state.updateDepartment = {
                ...state.updateDepartment,
                loading: false,
                success: true,
            };
        });

        builder.addCase(asyncDeleteDepartment.pending, (state: DepartmentAndBranchData) => {
            state.deleteDepartment = {
                ...state.deleteDepartment,
                loading: true,
            };
        });
        builder.addCase(asyncDeleteDepartment.rejected, (state: DepartmentAndBranchData, action) => {
            state.deleteDepartment = {
                ...state.deleteDepartment,
                loading: false,
                success: false,
                error: true,
                message: action.payload as string | object,
            };
        });
        builder.addCase(asyncDeleteDepartment.fulfilled, (state: DepartmentAndBranchData) => {
            state.deleteDepartment = {
                ...state.deleteDepartment,
                loading: false,
                success: true,
            };
        });
    },
});

export const {
    setDepartments,
    setDepartmentBranches,
    setPagination,
    resetDepartmentRequestState,
    resetDepartmentBranchRequestState,
    resetRegisterDepartmentRequestState,
    setBranchDetail,
    setDepartmentDetail,
    resetUpdateDepartmentRequestState,
    resetDeleteDepartmentRequestState,
    resetBranchDetail,
    resetDepartmentDetail,
} = departmentSlice.actions;
export const departmentSelector = (state: RootState) => state.department;
