import { Alert, Button, Form, Input, Modal } from 'antd';
import {
    ForgotPasswordEmailSendingRequest,
    selectForgotPassword,
    sendForgotPasswordEmail,
} from 'app/slice/forgotPasswordSlice';
import React, { useEffect } from 'react';
import { reset, setSuccessState, successModalSelector, SuccessModalState } from 'app/slice/successSlice';
import { useAppDispatch, useAppSelector } from 'app/hooks';

import sendingEmailSuccessIcon from 'assets/images/icon_send_mail.png';

type Props = {
    isVisible: boolean;
    onClose: () => void;
};

const CALLER_ORIGIN = 'ForgotPasswordModal';

const requiredRules = [{ required: true, message: 'メールアドレスをご入力ください。' }];

const ForgotPasswordModal = (props: Props) => {
    const { isVisible, onClose } = props;
    const [form] = Form.useForm();
    const dispatch = useAppDispatch();
    const { origin, isShow } = useAppSelector(successModalSelector);

    const { success = false, message, loading = false, status } = useAppSelector(selectForgotPassword);

    const onFormSubmit = (values: ForgotPasswordEmailSendingRequest) => {
        const { email } = values;
        dispatch(sendForgotPasswordEmail({ email }));
    };

    useEffect(() => {
        if (success) {
            const successModalPayload: SuccessModalState = {
                isShow: true,
                title: 'パスワード再発行',
                message: '入力されたメールアドレスに認証メールを送信しました。\n送信されたURLからパスワードの再設定をお願いします。',
                withButton: false,
                image: sendingEmailSuccessIcon,
                origin: CALLER_ORIGIN,
            };
            dispatch(setSuccessState(successModalPayload));
            onClose();
        }
    }, [dispatch, onClose, success]);

    useEffect(() => {
        if (origin === CALLER_ORIGIN && !isShow && isVisible) {
            form.resetFields();
            dispatch(reset());
        }
    }, [isShow, dispatch, origin, form, isVisible]);

    const onClickCancel = () => {
        onClose();
    };

    return (
        <Modal className='forgot-password-modal' visible={isVisible} onCancel={onClickCancel} footer={null}>
            <div className='title'>パスワード再発行</div>
            <div className='subtitle'>確認のため、ご登録のメールアドレスをご入力ください。</div>
            {status === 'failed' && <Alert type={'error'} message={message} className='my-3' />}
            <Form form={form} layout='vertical' onFinish={onFormSubmit} autoComplete='off'>
                <Form.Item name='email' label='メールアドレス' rules={requiredRules}>
                    <Input />
                </Form.Item>
                <Button type='primary' htmlType='submit' className='w-100' loading={loading}>
                    {!loading ? '送信' : ''}
                </Button>
            </Form>
            <div className='helper-text'>
                ※ご登録のメールアドレスをお忘れの場合は、大同生命本社または支社担当者にご確認ください。 <br /><br />
                ※各支社に一つ割り当てられたID・パスワードをお忘れの場合は、大同生命本社までお問い合わせください。
            </div>
        </Modal>
    );
};

export default ForgotPasswordModal;
