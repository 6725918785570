import { getAxiosClientWithToken } from './index';
import qs from 'qs';
import {
    CorporateFormData,
    RequestRegistrationMailRequest, RequestResaveAndResendRequest,
    TransformedTransferCorporateRequest,
} from 'app/slice/corporateSlice';
import { FetchEmployeeRequest } from 'types';

export const getEmployeesByAdmin = () => {
    const restClient = getAxiosClientWithToken();
    return restClient.get('/daido/employees');
};

export const getRegisteredCorporateUsersByDaidoEmployee = (employeeCode: string) => {
    const restClient = getAxiosClientWithToken();
    return restClient.post('/daido/employee/corporate/users', {
        employee_code: employeeCode,
    });
};

export const getAllDaidoEmployeesPerBranches = (params: FetchEmployeeRequest) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get('/daido/branches/employees', { params });
};

export const sendRegistrationMail = (data: RequestRegistrationMailRequest) => {
    const restClient = getAxiosClientWithToken();
    return restClient.post('/daido/corporate/registration', qs.stringify(data), {
        headers: {
            'Cache-Control': 'no-cache',
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
        },
    });
};

export const resendRegistrationEmail = (uuid: string) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get(`/daido/corporate/registration/resend/${uuid}`);
};

export const resaveAndResendRegistrationEmail = (uuid: string, data: RequestResaveAndResendRequest) => {
    const restClient = getAxiosClientWithToken();
    return restClient.patch(`/daido/corporate/${uuid}/resend`, qs.stringify(data), {
        headers: {
            'Cache-Control': 'no-cache',
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
        },
    });
};

export const getCorporateUserDetails = (uuid: string) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get(`/daido/corporate/${uuid}`);
};

export const updateCorporateDetails = (data: CorporateFormData, uuid: string) => {
    const restClient = getAxiosClientWithToken();
    return restClient.put(`/daido/corporate/${uuid}`, qs.stringify(data), {
        headers: {
            'Cache-Control': 'no-cache',
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
        },
    });
};

export const deleteCorporateUserApi = (uuid: string) => {
    const restClient = getAxiosClientWithToken();
    return restClient.delete(`/daido/corporate/${uuid}`);
};

export const removeCorporateUserLockApi = (uuid: string) => {
    const restClient = getAxiosClientWithToken();
    return restClient.patch(`/daido/corporate/${uuid}/unlock`);
};

export const transfer = (request: TransformedTransferCorporateRequest) => {
    const restClient = getAxiosClientWithToken();
    return restClient.patch('/daido/corporate/transfer', request);
};

export const totalDeleteCorporateUserApi = (uuid: string) => {
    const restClient = getAxiosClientWithToken();
    return restClient.delete(`/daido/corporate/${uuid}/hard`);
};
