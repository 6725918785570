import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';

import {RootState} from 'app/store';
import {FetchKotekiMeetingEmployeeDetailRequest} from "types";
import {getPublicSupportConsultationList} from "api/kouteki";
import {getErrorMessage} from "api";

export type KoutekiMeetingState = {
    loading: boolean,
    success: boolean,
    total: number,
    perPage: number,
    currentPage: number,
    lastPage: number,
    consultations: Application[],
};

export type Application = {
    uuid: string,
    corpUserUuid: string,
    fundsTitle?: string[],
    isDeadline: boolean,
    label: Label,
    schedule: string,
    specialist: Specialist[] | null,
    status: number,
    specialistSelected?: SpecialistSelected
};

type Consultation = {
    uuid: string,
    is_deadline: boolean,
    corp_user_id: string,
    funds_title?: string[],
    label: Label,
    schedule: string,
    specialists?: SpecialistData[],
    status: number,
    specialist_selected?: SpecialistSelectedData,
};

type SpecialistData = {
    uuid: string,
    role: string,
    email: string,
    name: string,
    address: string,
    label: string,
    office_name: string,
    profile_image_url: string,
    schedules: Schedule[],
    sub_profile_image_url: string,
};

type Schedule = {
    content: string,
    number: number,
}

export type Specialist = {
    uuid: string,
    role: string,
    email: string,
    name: string,
    address: string,
    label: string,
    officeName: string,
    profileImageUrl: string,
    schedules: Schedule[],
    subProfileImageUrl: string,
};

type SpecialistSelected = {
    profileImageUrl: string,
    subProfileImageUrl: string,
    uuid: string,
    role: string,
    label: string,
    email: string,
    name: string,
    officeName: string,
    phone: string,
};

type SpecialistSelectedData = {
    profile_image_url: string,
    sub_profile_image_url: string,
    uuid: string,
    role: string,
    label: string,
    email: string,
    name: string,
    office_name: string,
    phone: string,
};

export type Company = {
    address: string,
    allowSharoushi: number,
    allowShindanshi: number,
    hasCompanyInsurance: number,
    industry: string,
    laborRegulationsCompliant: number
    name: string,
};

export type Label = {
    content: string,
    color: string,
};

export type Fund = {
    code: string,
    title: string,
};

export const fetchPublicSupportConsultationListOfEmployee = createAsyncThunk('daido/employee/kouteki/meeting',
    async (params: FetchKotekiMeetingEmployeeDetailRequest, {dispatch, rejectWithValue}) => {
        try {
            if (params.status === undefined || params.status === -1) {
                delete params.status;
            }
            let response = await getPublicSupportConsultationList(params);
            const {data = [], pagination = {}, success} = response.data;
            if (success) {
                dispatch(setKoutekiMeetingsData({data, pagination}));
                return true;
            }

            return rejectWithValue(false);
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    });

export const employeekoutekiMeetingSlice = createSlice({
    name: 'employee-kouteki-meetting',
    initialState: {
        loading: false,
        success: false,
        total: 0,
        perPage: 0,
        currentPage: 0,
        lastPage: 0,
        consultations: [],
    } as KoutekiMeetingState,
    reducers: {
        setKoutekiMeetingsData: (state: KoutekiMeetingState, {payload}) => {
            state.total = payload.pagination.total;
            state.perPage = payload.pagination.per_page;
            state.currentPage = payload.pagination.current_page;
            state.lastPage = payload.pagination.last_page;
            state.consultations = payload.data.map((consultation: Consultation) => {
                return {
                    uuid: consultation.uuid,
                    corpUserUuid: consultation.corp_user_id,
                    fundsTitle: consultation.funds_title,
                    isDeadline: consultation.is_deadline,
                    label: consultation.label,
                    schedule: consultation.schedule,
                    specialist:  consultation.specialists && consultation.specialists.map((specialist: SpecialistData) => {
                        return {
                            uuid: specialist.uuid,
                            role: specialist.role,
                            email: specialist.email,
                            name: specialist.name,
                            address: specialist.address,
                            label: specialist.label,
                            officeName: specialist.office_name,
                            profileImageUrl: specialist.profile_image_url,
                            schedules: specialist.schedules,
                            subProfileImageUrl: specialist.sub_profile_image_url,
                        }
                    }),
                    status: consultation.status,
                    specialistSelected: consultation?.specialist_selected,
                } as Application
            });
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchPublicSupportConsultationListOfEmployee.pending, (state: KoutekiMeetingState) => {
            state.loading = true;
        });
        builder.addCase(fetchPublicSupportConsultationListOfEmployee.fulfilled, (state: KoutekiMeetingState) => {
            state.loading = false;
            state.success = true;
        });
        builder.addCase(fetchPublicSupportConsultationListOfEmployee.rejected, (state: KoutekiMeetingState) => {
            state.loading = false;
            state.success = false;
            state.total = 0;
            state.perPage = 0;
            state.currentPage = 0;
            state.lastPage = 0;
            state.consultations = [];
        });
    },
});

export const employeekoutekiMeetingSelector = (state: RootState) => state.employeeKoutekiMeeting;
export const {setKoutekiMeetingsData} = employeekoutekiMeetingSlice.actions;
