import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getApplicationsApi, GetApplicationsRequest, getApplicationStatusTypesApi } from 'api/application';
import { RootState } from 'app/store';

export type { GetApplicationsRequest } from 'api/application';

export type Fund = {
    code: string;
    title: string;
}

// Application Data Structure
export type Application = {
    uuid: string;
    status: number;
    label: {
        content: string;
        color: string
    };
    schedule: string | null;
    isDeadline: boolean | null;
    joinUrl: string | null;
    specialist: {
        uuid: string;
        role: string;
        email: string;
        name: string;
        officeName: string
    } | null;
    funds: Fund[];
}

// Application Status Data Structure, mainly for Filter function
export type ApplicationStatus = {
    code: string,
    name: string
}

// Pagination Data Structure returned by API call
export type Pagination = {
    count: number,
    total: number,
    perPage: number,
    currentPage: number,
    lastPage: number,
}

/**
 * GET applications list
 **/
export const getApplications = createAsyncThunk(
    '/company/applications',
    async (data: GetApplicationsRequest, { dispatch }) => {
        try {
            const response = await getApplicationsApi(data);
            dispatch(setApplications(response.data));
            return response.data;
        } catch (err) {
            return false;
        }
    },
);

/**
 * GET application statuses list
 **/
export const getApplicationStatusTypes = createAsyncThunk(
    '/company/status-types/application',
    async (data, { dispatch }) => {
        try {
            const response = await getApplicationStatusTypesApi();
            dispatch(setApplicationStatusTypes(response.data));
            return response.data;
        } catch (err) {
            return false;
        }
    },
);

/**
 * Create Application Slice
 */
export const applicationSlice = createSlice({
    name: 'applications',
    initialState: {
        applications: [{}] as Application[],
        applicationStatusTypes: [{}] as ApplicationStatus[],
        pagination: {} as Pagination,
    },
    reducers: {
        setApplications: (state, { payload }) => {
            state.applications = payload.data;
            state.pagination = payload.pagination;
        },
        setApplicationStatusTypes: (state, { payload }) => {
            state.applicationStatusTypes = payload.data;
        },
    },
});

export const { setApplications, setApplicationStatusTypes } = applicationSlice.actions;
export const selectApplications = (state: RootState) => state.applications;
