import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { RootState } from 'app/store';
import { getErrorMessage } from 'api';
import { importBranchApi, importDaidoEmployeeApi } from 'api/import';
import { isArray } from 'lodash';

export const IMPORT_BRANCH = 'IMPORT_BRANCH';
export const IMPORT_DAIDO_EMPLOYEE = 'IMPORT_DAIDO_EMPLOYEE';

type ImportState = {
    loading: boolean,
    success: boolean | null,
    errors: string[],
    type: string
}

export const importBranch = createAsyncThunk(
    'import/branch',
    async (data: File, { rejectWithValue }) => {
        try {
            const response = await importBranchApi(data);
            const { success } = response.data;

            if (success) {
                return true;
            }

            return rejectWithValue(false);
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    },
);

export const importDaidoEmployee = createAsyncThunk(
    'import/daido-employee',
    async (data: File, { rejectWithValue }) => {
        try {
            const response = await importDaidoEmployeeApi(data);
            const { success } = response.data;

            if (success) {
                return true;
            }

            return rejectWithValue(false);
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    },
);

export const importSlice = createSlice({
    name: 'import',
    initialState: {
        loading: false,
        success: null,
        errors: [],
        type: '',
    } as ImportState,
    reducers: {
        resetImportState: (state) => {
            state.loading = false;
            state.success = null;
            state.errors = [];
        },
    },
    extraReducers: (builder) => {
        builder.addCase(importBranch.pending, (state: ImportState) => {
            state.type = IMPORT_BRANCH;
            state.loading = true;
            state.success = null;
            state.errors = [];
        });
        builder.addCase(importBranch.fulfilled, (state: ImportState) => {
            state.type = IMPORT_BRANCH;
            state.loading = false;
            state.success = true;
        });
        builder.addCase(importBranch.rejected, (state: ImportState, { payload }) => {
            state.type = IMPORT_BRANCH;
            state.loading = false;
            state.success = false;

            if (isArray(payload)) {
                state.errors = payload;
            }
        });
        builder.addCase(importDaidoEmployee.pending, (state: ImportState) => {
            state.type = IMPORT_DAIDO_EMPLOYEE;
            state.loading = true;
            state.success = null;
            state.errors = [];
        });
        builder.addCase(importDaidoEmployee.fulfilled, (state: ImportState) => {
            state.type = IMPORT_DAIDO_EMPLOYEE;
            state.loading = false;
            state.success = true;
        });
        builder.addCase(importDaidoEmployee.rejected, (state: ImportState, { payload }) => {
            state.type = IMPORT_DAIDO_EMPLOYEE;
            state.loading = false;
            state.success = false;

            if (isArray(payload)) {
                state.errors = payload;
            }
        });
    },
});

export const { resetImportState } = importSlice.actions;
export const importSelector = (state: RootState) => state.import;
