import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { getErrorMessage } from 'api';
import { createApplication, fetchApplicationStatus } from 'api/funds';

export type FundApplicationState = {
    success: boolean | null,
    message: string,
    loading: boolean,
    status: number,
    finished: boolean,
    matched: boolean,
    applicationUuid: string
}

export type FundApplicationRequest = {
    code: string,
    remarks: string
}[];

export type ApplicationStatusResponse = {
    status: number
    message: string,
    finished: boolean,
    matched: boolean
}

export const postApplication = createAsyncThunk(
    'funds/application',
    async (data: FundApplicationRequest, { dispatch, rejectWithValue }) => {
        try {
            let response = await createApplication(data);

            if (response.data.success === true) {
                dispatch(setApplicationUuid(response.data.data.uuid));
                return true;
            }

            return rejectWithValue('Server error.');
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    },
);

export const getApplicationStatus = createAsyncThunk(
    'funds/applicationStatus',
    async (_, { rejectWithValue, getState }) => {
        try {
            let { applicationUuid } = selectFundApplication(getState() as RootState);
            let response = await fetchApplicationStatus(applicationUuid);

            if (response.data.success === true) {
                return response.data.data;
            }

            return rejectWithValue('Server error.');
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    },
);

export const fundApplicationSlice = createSlice({
    name: 'fundApplication',
    initialState: {
        success: null,
        message: '',
        loading: false,
        status: 0,
        finished: false,
        matched: false,
        applicationUuid: '',
    } as FundApplicationState,
    reducers: {
        setApplicationUuid: (state, action) => {
            state.applicationUuid = action.payload;
        },
        resetApplication: (state) => {
            state.success = null;
            state.message = '';
            state.loading = false;
            state.status = 0;
            state.finished = false;
            state.matched = false;
            state.applicationUuid = '';
        },
    },
    extraReducers: (builder) => {
        // postApplication action pending
        builder.addCase(postApplication.pending, (state: FundApplicationState) => {
            state.loading = true;
        });
        // postApplication action rejected
        builder.addCase(postApplication.rejected, (state: FundApplicationState, action) => {
            state.loading = false;
            state.success = false;
            state.message = action.payload as string;
        });
        // postApplication action fulfilled
        builder.addCase(postApplication.fulfilled, (state: FundApplicationState) => {
            state.loading = false;
            state.success = true;
        });
        // getApplicationStatus action fulfilled
        builder.addCase(getApplicationStatus.fulfilled, (state: FundApplicationState, action) => {
            const { status, matched, finished } = action.payload as ApplicationStatusResponse;

            state.status = status;
            state.matched = matched;
            state.finished = finished;
        });
    },
});

export const selectFundApplication = (state: RootState) => state.fundApplication;
export const { setApplicationUuid, resetApplication } = fundApplicationSlice.actions;
