import { Link } from 'react-router-dom';

import { Breadcrumb as BreadCrumbs } from 'antd';
import React from 'react';

type Props = {
    breadcrumb: Breadcrumb[];
    style?: React.CSSProperties;
};

export type Breadcrumb = {
    name: string;
    url: string;
    state?: any;
};

/**
 * Creates Breadcrumb
 * @param { Props } props
 * @return BreadCrumbs
 * */
const Breadcrumbs = (props: Props) => {
    const { breadcrumb, style } = props;

    const renderItem = (crumb: Breadcrumb) => {
        return (
            <BreadCrumbs.Item key={crumb.url}>
                {crumb.url !== '' ? (
                    <Link to={crumb.url} state={crumb?.state}>{crumb.name}</Link>
                ) : (
                    crumb.name
                )}
            </BreadCrumbs.Item>
        );
    };

    return (
        <BreadCrumbs separator='>' style={style}>
            {breadcrumb?.map((bread) => renderItem(bread))}
        </BreadCrumbs>
    );
};

export default Breadcrumbs;
