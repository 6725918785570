import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { StyleList } from 'types';
import { Rule } from 'antd/lib/form';
import { useLocation, useSearchParams } from 'react-router-dom';
import classNames from 'classnames';
import { FormItemProps } from 'antd/lib/form/FormItem';
import { get, has, isObject } from 'lodash';
import { Consultation, ConsultationDetailType } from 'app/slice/consultationSlice';
import { APPLICATION_POLICY_TYPE_LABELS } from 'config/constant';

/**
 * Set body id for styling
 *
 * @param {string} style
 */
export const useStyle = (...style: StyleList[]) => {
    document.body.id = style.join(' ');
};

/**
 * Scroll to top
 */
export const scrollToTop = (behavior?: 'smooth' | 'auto') => window.scroll({
    top: 0,
    left: 0,
    behavior: behavior,
});

/**
 * Rule for checking password match
 *
 * @param {string} fieldName
 * @param {string} message
 * @returns {({getFieldValue}: {getFieldValue: any}) => {validator(_, value): (Promise<never>)}}
 */
export const validatePasswordConfirmation: (fieldName: string, message: string) => Rule = (fieldName, message) => {
    return ({ getFieldValue }) => ({
        validator(_, value) {
            if (value && getFieldValue(fieldName) !== value) {
                return Promise.reject(new Error(message));
            }
            return Promise.resolve();
        },
    });
};

export const isDevMode = () => {
    return process.env.NODE_ENV === 'development' || window.location.hostname.includes('dev');
};

// A custom hook that builds on useLocation to parse
// the query string for you.
export const useQuery = () => {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
};

export const useIsMobile = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    function handleWindowSizeChange() {
        setIsMobile(window.innerWidth <= 768);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        handleWindowSizeChange();

        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        };
    }, []);

    return isMobile;
};

export const activeClass = (condition: boolean, activeClassNames?: string | undefined, defaultClassNames?: string | undefined) => {
    return classNames({ 'active': condition }, { [`${activeClassNames}`]: condition }, defaultClassNames);
};

export const activeMenuClass = (condition: boolean, defaultClassNames?: string | undefined) => {
    return activeClass(condition, 'ant-menu-item-selected', defaultClassNames);
};

export const renderValidate = (messages: any, key: string) => {
    if (isObject(messages) && has(messages, key)) {
        return { validateStatus: 'error', help: get(messages, key) } as FormItemProps;
    }

    return { validateStatus: undefined, help: undefined } as FormItemProps;
};

export function useDebounce<T>(value: T, delay: number): T {
    const [debouncedValue, setDebouncedValue] = useState<T>(value);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);
        return () => clearTimeout(handler);
    }, [value, delay]);

    return debouncedValue;
}

export const isWorkRegulation = (consultation?: Consultation) => consultation?.type && APPLICATION_POLICY_TYPE_LABELS.includes(consultation?.type);

export const getBreadcrumbUrlConsultationDetail = (type: ConsultationDetailType, customerUuid?: string, searchQuery?: string) => {
    switch (type) {
        case 'customerDetail':
            return `/dashboard/customer/${customerUuid}` + searchQuery;
        case 'kisokuDetail':
            return '/dashboard/kisoku' + searchQuery;
        case 'koutekiDetail':
            return '/dashboard/kouteki' + searchQuery;
        default:
            return '';
    }
};

export const downloadBlob = (filename: string, blob: Blob) => {
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(new Blob([blob]));
    link.setAttribute('download', filename);
    link.click();
};

export const useParamsState = <T = string>(
    key: string,
    defaultValue: any = null,
    transform: ((value: any) => any) = (value) => value,
): [T, (value: T) => void] => {
    const [searchParams, setSearchParams] = useSearchParams();

    return [
        useMemo(
            () => transform(searchParams.get(key) ?? defaultValue),
            [defaultValue, key, searchParams, transform],
        ),
        useCallback((value: T) => {
            const query = value as any;

            if (searchParams.get(key) !== query) {
                setSearchParams({ ...Object.fromEntries(searchParams), [key]: query });
            }
        }, [key, searchParams, setSearchParams]),
    ];
};
