import React, { useEffect, useState } from 'react';
import { Button, Pagination, Table } from 'antd';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { downloadBlob } from 'lib/utils';
import moment from 'moment';
import Column from 'antd/lib/table/Column';
import { useNavigate } from 'react-router-dom';
import {
    downloadKisokuTemplateList,
    getKisokuTemplateList, KisokuTemplateList,
    resetDownloadTemplateList,
    selectKisokuTemplate,
} from 'app/slice/kisokuTemplateSlice';

const KisokuTemplateManagement = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { list: { data, pagination }, loading, download } = useAppSelector(selectKisokuTemplate);
    const [page, setPage] = useState(1);

    useEffect(() => {
        dispatch(getKisokuTemplateList({ page }));
    }, [dispatch, page]);

    const onChangePage = (page: number) => {
        setPage(page);
    };

    const downloadFile = () => {
        dispatch(downloadKisokuTemplateList());
    };

    useEffect(() => {
        if (download.success && download.data) {
            downloadBlob('ひな形ダウンロード集計履歴-' + moment().format('YYYYMMDDHHmmss') + '.xlsx', download.data);
            dispatch(resetDownloadTemplateList());
        }
    }, [download.data, dispatch, download.success]);

    const renderActionButton = (_: any, data: KisokuTemplateList) => {
        const onDetailClick = () => {
            navigate(`/dashboard/kisoku-template/${data.displayDate}`);
        };
        return (
            <div className='action-buttons'>
                <Button className='edit-button' onClick={onDetailClick}>詳細</Button>
            </div>
        );
    };

    return (
        <>
            <div className='content diagnostic-export-management'>
                <div className='header-container'>
                    <p className='title'>ひな形ダウンロード集計履歴</p>
                    <div className='diagnostic-export-action-button '>
                        <Button type='primary' onClick={downloadFile} loading={download.loading}>データ出力</Button>
                    </div>
                </div>
                <Table dataSource={data}
                       rowKey='displayDate'
                       className={'my-2'}
                       loading={loading}
                       pagination={false}
                >
                    <Column title='集計月' dataIndex='displayDate' key='displayDate' />
                    <Column title='ひな形ダウンロード数' dataIndex='count' key='count' />
                    <Column width='10%' dataIndex='actionButtons' key='actionButtons'
                            render={renderActionButton}
                    />
                </Table>
                {pagination?.total > 0 && (
                    <Pagination className='mt-3 d-flex justify-content-end' defaultPageSize={pagination?.perPage}
                                current={page}
                                total={pagination?.total}
                                onChange={onChangePage}
                                showSizeChanger={false}
                    />
                )}
            </div>
        </>
    );
};

export default KisokuTemplateManagement;
