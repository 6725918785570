import { Outlet, useNavigate } from 'react-router-dom';
import React, { useEffect } from 'react';
import { useAppSelector } from 'app/hooks';

import { Header } from 'antd/lib/layout/layout';
import { Layout } from 'antd';
import { authSelector } from 'app/slice/authSlice';
import logo from 'assets/images/daidolife_logo_light.png';
import { useStyle } from 'lib/utils';
import SystemNotification from 'components/common/SystemNotification';

const MainLayout = () => {
    const navigate = useNavigate();
    const { token, roleName } = useAppSelector(authSelector);

    useStyle('labor-social');

    useEffect(() => {
        if (roleName && token) {
            navigate('/dashboard/management');
        }
    }, [token, navigate, roleName]);

    return (
        <Layout>
            <SystemNotification />
            <Header className='labor_social_navbar'>
                <div className='title'>
                    <img className='main-logo' width={200} src={logo} alt='logo' />
                    <span className='sub-title'>公的支援オンラインサービス／就業規則診断オンラインサービス</span>
                </div>
            </Header>
            <Outlet />
        </Layout>
    );
};

export default MainLayout;
