import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getDashboardDetails, getDashboardProfile, updateProfile } from 'api/dashboard';

import { RootState } from 'app/store';
import { getErrorMessage } from 'api';
import { setName } from 'app/slice/authSlice';

export type DashboardData = {
    corpUsers?: number;
    numberOfMeetings?: number;
    daidoEmployees?: number;
    diagnoses?: number;
    numberOfMeetingsKouteki?: number;
    numberOfMeetingsKisoku?: number;
    diagnosesLoggedIn?: number;
    diagnosesLoggedInDaido?: number;
    diagnosesKisoku?:number;
    kisokuTemplateCount?:number;
    profile?: DaidoBranchProfile;
    daidoBranchRequest: Request;
    isRequesting: boolean;
    isRequestSuccess: boolean;
    isRequestError: boolean;
    messageFromRequest: string;
};

type Request = {
    isRequesting: boolean;
    isRequestSuccess: boolean;
    isRequestError: boolean;
    messageFromRequest: string;
};

export type DaidoBranchProfile = {
    code?: string;
    name?: string;
    branchCode?: string;
    branchName?: string;
    departmentCode?: string;
    departmentId?: number;
    departmentName?: string;
    representativeLastName?: string;
    representativeFirstName?: string;
    email?: string;
    emailExtension?: string;
    representativeCode?: string;
};

type UpdateProfileRequest = {
    representativeFirstName: string;
    representativeLastName: string;
    email: string;
    emailExtension: string;
}

export const fetchDashboardDetails = createAsyncThunk('daido/dashboard', async (data, { dispatch, rejectWithValue }) => {
    try {
        let response = await getDashboardDetails();
        const { data = {}, success } = response.data;
        if (success) {
            dispatch(setDashboardDetails(data));
            return true;
        }

        return rejectWithValue('Server error.');
    } catch (error: any) {
        return rejectWithValue(getErrorMessage(error));
    }
});

export const fetchDaidoBranchProfile = createAsyncThunk('daido/profile', async (data, { dispatch, rejectWithValue }) => {
    try {
        let response = await getDashboardProfile();
        const { data = {}, success } = response.data;
        if (success) {
            dispatch(setDashboardProfile(data));
            return true;
        }

        return rejectWithValue('Server error.');
    } catch (error: any) {
        return rejectWithValue(getErrorMessage(error));
    }
});

export const updateDaidoBranchProfile = createAsyncThunk(
    'daido/profile/update',
    async ({representativeFirstName, representativeLastName, email, emailExtension}: UpdateProfileRequest, { dispatch, rejectWithValue }) => {
        try {
            let response = await updateProfile(representativeFirstName, representativeLastName, email.concat(emailExtension));
            const { data = {}, success } = response.data;
            if (success) {
                dispatch(setUpdatedProfile(data));
                dispatch(setName(data));
                return true;
            }

            return rejectWithValue('Server error.');
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState: {
        corpUsers: 0,
        numberOfMeetings: 0,
        daidoEmployees: 0,
        diagnoses: 0,
        numberOfMeetingsKouteki: 0,
        numberOfMeetingsKisoku: 0,
        diagnosesLoggedIn : 0,
        diagnosesKisoku: 0,
        kisoku_template_count: 0,
        profile: {} as DaidoBranchProfile,
        daidoBranchRequest: {
            isRequestError: false,
            isRequestSuccess: false,
            isRequesting: false,
            messageFromRequest: '',
        },
        isRequestError: false,
        isRequestSuccess: false,
        isRequesting: false,
        messageFromRequest: '',
    } as DashboardData,
    reducers: {
        setDashboardDetails: (state, action) => {
            const { corp_users, number_of_meetings, daido_employees, diagnoses, number_of_meetings_kouteki, number_of_meetings_kisoku, diagnoses_logged_in, diagnoses_logged_in_daido, diagnoses_kisoku, kisoku_template_count } =
                action.payload;
            state.corpUsers = corp_users;
            state.numberOfMeetings = number_of_meetings;
            state.daidoEmployees = daido_employees;
            state.diagnoses = diagnoses;
            state.numberOfMeetingsKouteki = number_of_meetings_kouteki;
            state.numberOfMeetingsKisoku = number_of_meetings_kisoku;
            state.diagnosesLoggedIn = diagnoses_logged_in;
            state.diagnosesLoggedInDaido = diagnoses_logged_in_daido;
            state.diagnosesKisoku = diagnoses_kisoku
            state.kisokuTemplateCount = kisoku_template_count
        },
        setDashboardProfile: (state, action) => {
            const { profile } = action.payload;
            state.profile = {
                branchCode: profile?.branch_code,
                branchName: profile?.branch_name,
                departmentCode: profile?.department_code,
                departmentId: profile?.department_id,
                departmentName: profile?.department_name,
                email: profile.email,
                emailExtension: profile?.email && profile?.email.substring(profile.email?.lastIndexOf('@')),
                representativeCode: profile.representative_code,
                representativeFirstName: profile.representative_firstname,
                representativeLastName: profile.representative_lastname,
            };
        },
        setUpdatedProfile: (state, action) => {
            const { representative_firstname, representative_lastname, email } = action.payload;
            state.profile = {
                ...state.profile,
                representativeFirstName: representative_firstname,
                representativeLastName: representative_lastname,
                emailExtension: email?.substring(email?.lastIndexOf('@')),
            };
        },
        resetAllProfileState: (state) => {
            state.corpUsers = 0;
            state.numberOfMeetings = 0;
            state.daidoEmployees = 0;
            state.diagnoses = 0;
            state.numberOfMeetingsKouteki = 0;
            state.numberOfMeetingsKisoku = 0;
            state.kisokuTemplateCount = 0;
            state.profile = undefined;
        },
        resetRequestState: (state) => {
            state.isRequestError = false;
            state.isRequestSuccess = false;
            state.isRequesting = false;
            state.messageFromRequest = '';
            state.daidoBranchRequest = {
                isRequestError: false,
                isRequestSuccess: false,
                isRequesting: false,
                messageFromRequest: '',
            };
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchDaidoBranchProfile.pending, (state: DashboardData) => {
            state.daidoBranchRequest = {
                ...state.daidoBranchRequest,
                isRequesting: true,
            };
        });
        builder.addCase(fetchDaidoBranchProfile.rejected, (state: DashboardData, action) => {
            state.daidoBranchRequest = {
                ...state.daidoBranchRequest,
                isRequesting: false,
                isRequestSuccess: false,
                isRequestError: true,
                messageFromRequest: action.payload as string,
            };
        });
        builder.addCase(fetchDaidoBranchProfile.fulfilled, (state: DashboardData) => {
            state.daidoBranchRequest = {
                ...state.daidoBranchRequest,
                isRequesting: false,
                isRequestSuccess: true,
            };
        });

        builder.addCase(fetchDashboardDetails.pending, (state: DashboardData) => {
            state.isRequesting = true;
        });

        builder.addCase(fetchDashboardDetails.rejected, (state: DashboardData, action) => {
            state.isRequesting = false;
            state.isRequestSuccess = false;
            state.isRequestError = true;
            state.messageFromRequest = action.payload as string;
        });

        builder.addCase(fetchDashboardDetails.fulfilled, (state: DashboardData) => {
            state.isRequesting = false;
            state.isRequestSuccess = true;
        });

        builder.addCase(updateDaidoBranchProfile.pending, (state: DashboardData) => {
            state.isRequesting = true;
        });

        builder.addCase(updateDaidoBranchProfile.rejected, (state: DashboardData, action) => {
            state.isRequesting = false;
            state.isRequestSuccess = false;
            state.isRequestError = true;
            state.messageFromRequest = action.payload as string;
        });

        builder.addCase(updateDaidoBranchProfile.fulfilled, (state: DashboardData) => {
            state.isRequesting = false;
            state.isRequestSuccess = true;
        });
    },
});

export const { setDashboardDetails, setDashboardProfile, resetAllProfileState, resetRequestState, setUpdatedProfile } =
    dashboardSlice.actions;
export const dashboardSelector = (state: RootState) => state.dashboard;
