import React, { useEffect, useMemo, useState } from 'react';
import { Button, Pagination, Table } from 'antd';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { downloadBlob } from 'lib/utils';
import moment from 'moment';
import Column from 'antd/lib/table/Column';
import { useParams } from 'react-router-dom';
import Breadcrumbs, { Breadcrumb } from 'components/common/Breadcrumb';
import {
    downloadKisokuTemplate,
    getKisokuTemplate,
    resetDownloadTemplateList,
    selectKisokuTemplate,
} from 'app/slice/kisokuTemplateSlice';

type Params = {
    year: string,
    month: string,
}

const KisokuTemplateDetails = () => {
    const { year, month } = useParams<Params>();
    const dispatch = useAppDispatch();
    const { data: { data, pagination }, loading, download } = useAppSelector(selectKisokuTemplate);
    const [page, setPage] = useState(1);

    const title = useMemo(() => moment(`${year}-${month}`, 'YYYY-MM').format('YYYY年MM月'), [year, month]);

    const onChangePage = (page: number) => {
        setPage(page);
    };

    useEffect(() => {
        if (year && month) {
            dispatch(getKisokuTemplate({ page, year, month }));
        }
    }, [dispatch, month, year, page]);

    const downloadFile = () => {
        if (year && month) {
            dispatch(downloadKisokuTemplate({ year, month }));
        }
    };

    useEffect(() => {
        if (download.success && download.data) {
            downloadBlob('ひな形ダウンロード集計履歴-' + title + '-' + moment().format('YYYYMMDDHHmmss') + '.xlsx', download.data);
            dispatch(resetDownloadTemplateList());
        }
    }, [download.data, dispatch, download.success, title]);

    const breadcrumbs: Breadcrumb[] = [
        {
            name: 'ひな形ダウンロード集計履歴',
            url: '/dashboard/kisoku-template',
        },
        {
            name: title,
            url: '',
        },
    ];

    return (
        <>
            <Breadcrumbs breadcrumb={breadcrumbs} />
            <div className='content kisoku-template-management'>
                <div className='header-container'>
                    <p className='title'>{title}</p>
                    <div className='kisoku-template-action-button'>
                        <Button type='primary' onClick={downloadFile} loading={download.loading}>データ出力</Button>
                    </div>
                </div>
                <Table dataSource={data}
                       rowKey='email'
                       className={'my-2'}
                       loading={loading}
                       pagination={false}
                >
                    <Column className='align-top' title='ダウンロード日時' dataIndex='createdAt' key='createdAt' />
                    <Column className='align-top' title='お客さま名' dataIndex='companyName' key='companyName' />
                    <Column className='align-top' title='メールアドレス' dataIndex='email' key='email' />
                    <Column className='align-top' title='ひな形名称' dataIndex='filename' key='filename'/>
                </Table>
                {pagination?.total > 0 && (
                    <Pagination className='mt-3 d-flex justify-content-end' defaultPageSize={pagination?.perPage}
                                current={page}
                                total={pagination?.total}
                                onChange={onChangePage}
                                showSizeChanger={false}
                    />
                )}
            </div>
        </>
    );
};

export default KisokuTemplateDetails;
