import { getAxiosClientWithToken } from './index';

export const getDashboardDetails = () => {
    const restClient = getAxiosClientWithToken();

    return restClient.get('/daido/dashboard');
};

export const getDashboardProfile = () => {
    const restClient = getAxiosClientWithToken();

    return restClient.get('/daido/profile');
};

export const updateProfile = (representativeFirstName: string, representativeLastName: string, email: string) => {
    const restClient = getAxiosClientWithToken();
    return restClient.put('/daido/profile', {
        representative_firstname: representativeFirstName,
        representative_lastname: representativeLastName,
        email: email
    });
};
