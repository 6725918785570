import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';
import { getErrorMessage } from 'api';
import {
    downloadCorporateListApi,
    downloadDaidoEmployeeListApi,
    downloadKisokuApi,
    downloadKoutekiApi,
    getCompaniesApi,
    getDaidoEmployeeApi,
    getKisokuApi,
    getKoutekiApi,
} from 'api/export';
import { RootState } from 'app/store';

export type Dates = string[];

export type ExportUserRequest = {
    finish?: Dates;
    type?: number;
    page?: number;
}

export type ExportCompanyRequest = {
    start?: Dates;
    finish?: Dates;
    type?: number;
    page?: number;
}

export type ExportApplicationRequest = {
    from?: string;
    to?: string;
    type?: number;
    page?: number;
}

export const downloadDaidoEmployeeList = createAsyncThunk(
    'export/daidoEmployeeList',
    async (params: ExportUserRequest, { dispatch, rejectWithValue }) => {
        try {
            let response = await downloadDaidoEmployeeListApi(params);
            dispatch(setExportDaidoEmployee(response.data));
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    });

export const downloadCorporateList = createAsyncThunk(
    'export/corporateList',
    async (params: ExportCompanyRequest, { dispatch, rejectWithValue }) => {
        try {
            let response = await downloadCorporateListApi(params);
            dispatch(setExportCorporate(response.data));
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    });

export const downloadKoutekiList = createAsyncThunk(
    'export/downloadKouteki',
    async (params: ExportApplicationRequest, { dispatch, rejectWithValue }) => {
        try {
            let response = await downloadKoutekiApi({ ...params, type: 1 });
            dispatch(setExportKouteki(response.data));
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    });

export const downloadKisokuList = createAsyncThunk(
    'export/downloadKisoku',
    async (params: ExportApplicationRequest, { dispatch, rejectWithValue }) => {
        try {
            let response = await downloadKisokuApi({ ...params, type: 2 });
            dispatch(setExportKisoku(response.data));
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    });

export const getDaidoEmployees = createAsyncThunk(
    'export/getEmployees',
    async (params: ExportUserRequest, { dispatch, rejectWithValue }) => {
        try {
            let response = await getDaidoEmployeeApi(params);
            dispatch(setDaidoEmployeeDetails(response.data));
            dispatch(setPagination(response.data.pagination));
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    });

export const getCompanies = createAsyncThunk(
    'export/getCompanies',
    async (params: ExportCompanyRequest, { dispatch, rejectWithValue }) => {
        try {
            let response = await getCompaniesApi(params);
            dispatch(setCompanies(response.data));
            dispatch(setPagination(response.data.pagination));
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    });

export const getKouteki = createAsyncThunk(
    'export/getKouteki',
    async (params: ExportApplicationRequest, { dispatch, rejectWithValue }) => {
        try {
            let response = await getKoutekiApi(params);
            dispatch(setApplications(response.data));
            dispatch(setPagination(response.data.pagination));
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    });

export const getKisoku = createAsyncThunk(
    'export/getKouteki',
    async (params: ExportApplicationRequest, { dispatch, rejectWithValue }) => {
        try {
            let response = await getKisokuApi(params);
            dispatch(setApplications(response.data));
            dispatch(setPagination(response.data.pagination));
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    });

type DownloadDaidoEmployee = {
    data: Blob | null;
    loading: boolean;
    success: boolean;
}

type DownloadCorporate = {
    data: Blob | null;
    loading: boolean;
    success: boolean;
}

type DownloadKouteki = {
    koutekiData: Blob | null;
    koutekiLoading: boolean;
    koutekiSuccess: boolean;
}

type DownloadKisoku = {
    kisokuData: Blob | null;
    kisokuLoading: boolean;
    kisokuSuccess: boolean;
}

type DaidoEmployeesResponse = {
    registration_finish_date: string,
    updated_at: string,
    branch_code: string,
    branch_name: string,
    department_code: string,
    department_name: string,
    employee_code: string,
    employee_name: string,
    employee_email: string,
    handled_company_count: number,
    count_meeting_joined: number
}

export type DaidoEmployees = {
    registrationFinishDate: string,
    updatedAt: string,
    branchCode: string,
    branchName: string,
    departmentCode: string,
    departmentName: string,
    employeeCode: string,
    employeeName: string,
    employeeEmail: string,
    handledCompanyCount: number,
    countMeetingJoined: number
}

type CompaniesResponse = {
    registration_start_date: string,
    registration_finish_date: string,
    updated_at: string,
    name: string,
    name_furigana: string,
    postal_code: string,
    prefecture: string,
    address: string,
    building_name: string,
    representative_name: string,
    representative_name_kana: string,
    representative_position: string,
    phone: string,
    email: string,
    industry: string,
    employee_count_range: string,
    parttime_employee_count: number,
    company_insurances: string,
    labor_regulations_compliant: boolean,
    allow_sharoushi: boolean,
    allow_shindanshi: boolean,
    branch_code: string,
    branch_name: string,
    department_code: string,
    department_name: string,
    daido_employee_code: string,
    daido_employee_name: string,
    kouteki_application_count: number,
    kisoku_application_count: number,
}

export type Companies = {
    registrationStartDate: string,
    registrationFinishDate: string,
    updatedAt: string,
    name: string,
    nameFurigana: string,
    postalCode: string,
    prefecture: string,
    address: string,
    buildingName: string,
    representativeName: string,
    representativeNameKana: string,
    representativePosition: string,
    phone: string,
    email: string,
    industry: string,
    employeeCountRange: string,
    parttimeEmployeeCount: number,
    companyInsurances: string,
    laborRegulationsCompliant: boolean,
    allowSharoushi: boolean,
    allowShindanshi: boolean,
    branchCode: string,
    branchName: string,
    departmentCode: string,
    departmentName: string,
    daidoEmployeeCode: string,
    daidoEmployeeName: string,
    koutekiApplicationCount: number,
    kisokuApplicationCount: number,
}

type ApplicationResponse = {
    meeting_created_at: string,
    branch_code: string,
    branch_name: string,
    department_code: string,
    department_name: string,
    daido_employee_code: string,
    daido_employee_name: string,
    name: string,
    name_furigana: string,
    postal_code: string,
    prefecture: string,
    address: string,
    building_name: string,
    representative_name: string,
    representative_name_kana: string,
    representative_position: string,
    phone: string,
    email: string,
    industry: string,
    employee_count_range: string,
    parttime_employee_count: number,
    company_insurances: string,
    labor_regulations_compliant: boolean,
    allow_sharoushi: boolean,
    allow_shindanshi: boolean,
    schedule: string,
    policy_type: string,
    topics: string,
    funds: string,
    status: string,
    daido_joins: string,
    type: string,
    formatted_schedule: string,
    specialist_name: string,
    remarks: string,
}

export type Application = {
    meetingCreatedDate: string,
    branchCode: string,
    branchName: string,
    departmentCode: string,
    departmentName: string,
    daidoEmployeeCode: string,
    daidoEmployeeName: string,
    name: string,
    nameFurigana: string,
    postalCode: string,
    prefecture: string,
    address: string,
    buildingName: string,
    representativeName: string,
    representativeNameKana: string,
    representativePosition: string,
    phone: string,
    email: string,
    industry: string,
    employeeCountRange: string,
    parttimeEmployeeCount: number,
    companyInsurances: string,
    laborRegulationsCompliant: boolean,
    allowSharoushi: boolean,
    allowShindanshi: boolean,
    schedule: string,
    policyType: string,
    topics: string,
    funds: string,
    status: string,
    daidoJoins: string,
    type: string,
    formattedSchedule: string,
    specialistName: string,
    remarks: string,
}

type Pagination = {
    count: number,
    currentPage: number,
    lastPage: number,
    perPage: number,
    total: number,
}

type ExportStates = {
    downloadDaidoEmployee: DownloadDaidoEmployee;
    downloadCorporate: DownloadCorporate;
    downloadKouteki: DownloadKouteki;
    downloadKisoku: DownloadKisoku;
    daidoEmployees: DaidoEmployees[];
    companies: Companies[];
    applications: Application[];
    loading: boolean;
    pagination: Pagination;
}

export const exportSlice = createSlice({
    name: 'export',
    initialState: {
        downloadDaidoEmployee: {
            loading: false,
            success: false,
        } as DownloadDaidoEmployee,
        downloadCorporate: {
            loading: false,
            success: false,
        } as DownloadCorporate,
        downloadKouteki: {
            koutekiLoading: false,
            koutekiSuccess: false,
        } as DownloadKouteki,
        downloadKisoku: {
            kisokuLoading: false,
            kisokuSuccess: false,
        } as DownloadKisoku,
        daidoEmployees: [] as DaidoEmployees[],
        companies: [] as Companies[],
        applications: [] as Application[],
        loading: false,
        pagination: {} as Pagination,
    } as ExportStates,
    reducers: {
        setExportDaidoEmployee: (state: ExportStates, { payload }) => {
            state.downloadDaidoEmployee.data = payload;
        },
        setExportCorporate: (state: ExportStates, { payload }) => {
            state.downloadCorporate.data = payload;
        },
        setExportKouteki: (state: ExportStates, { payload }) => {
            state.downloadKouteki.koutekiData = payload;
        },
        setExportKisoku: (state: ExportStates, { payload }) => {
            state.downloadKisoku.kisokuData = payload;
        },
        setDaidoEmployeeDetails: (state: ExportStates, { payload }) => {
            state.daidoEmployees = [...payload.data].map((employee: DaidoEmployeesResponse, index: number) => ({
                key: index,
                registrationFinishDate: employee.registration_finish_date,
                updatedAt: employee.updated_at,
                branchCode: employee.branch_code,
                branchName: employee.branch_name,
                departmentCode: employee.department_code,
                departmentName: employee.department_name,
                employeeCode: employee.employee_code,
                employeeName: employee.employee_name,
                employeeEmail: employee.employee_email,
                countMeetingJoined: employee.count_meeting_joined,
                handledCompanyCount: employee.handled_company_count,
            }));
        },
        setCompanies: (state: ExportStates, { payload }) => {
            state.companies = [...payload.data].map((company: CompaniesResponse, index: number) => ({
                key: index,
                registrationStartDate: company.registration_start_date,
                registrationFinishDate: company.registration_finish_date,
                updatedAt: company.updated_at,
                name: company.name,
                nameFurigana: company.name_furigana,
                postalCode: company.postal_code,
                prefecture: company.prefecture,
                address: company.address,
                buildingName: company.building_name,
                representativeName: company.representative_name,
                representativeNameKana: company.representative_name_kana,
                representativePosition: company.representative_position,
                phone: company.phone,
                email: company.email,
                industry: company.industry,
                employeeCountRange: company.employee_count_range,
                parttimeEmployeeCount: company.parttime_employee_count,
                companyInsurances: company.company_insurances,
                laborRegulationsCompliant: company.labor_regulations_compliant,
                allowSharoushi: company.allow_sharoushi,
                allowShindanshi: company.allow_shindanshi,
                branchCode: company.branch_code,
                branchName: company.branch_name,
                departmentCode: company.department_code,
                departmentName: company.department_name,
                daidoEmployeeCode: company.daido_employee_code,
                daidoEmployeeName: company.daido_employee_name,
                koutekiApplicationCount: company.kouteki_application_count,
                kisokuApplicationCount: company.kisoku_application_count,
            }));
        },
        setApplications: (state: ExportStates, { payload }) => {
            state.applications = [...payload.data].map((application: ApplicationResponse, index: number) => ({
                key: index,
                meetingCreatedDate: application.meeting_created_at,
                branchCode: application.branch_code,
                branchName: application.branch_name,
                departmentCode: application.department_code,
                departmentName: application.department_name,
                daidoEmployeeCode: application.daido_employee_code,
                daidoEmployeeName: application.daido_employee_name,
                name: application.name,
                nameFurigana: application.name_furigana,
                postalCode: application.postal_code,
                prefecture: application.prefecture,
                address: application.address,
                buildingName: application.building_name,
                representativeName: application.representative_name,
                representativeNameKana: application.representative_name_kana,
                representativePosition: application.representative_position,
                phone: application.phone,
                email: application.email,
                industry: application.industry,
                employeeCountRange: application.employee_count_range,
                parttimeEmployeeCount: application.parttime_employee_count,
                companyInsurances: application.company_insurances,
                laborRegulationsCompliant: application.labor_regulations_compliant,
                allowSharoushi: application.allow_sharoushi,
                allowShindanshi: application.allow_shindanshi,
                schedule: application.schedule,
                policyType: application.policy_type,
                topics: application.topics,
                funds: application.funds,
                status: application.status,
                daidoJoins: application.daido_joins,
                type: application.type,
                formattedSchedule: application.formatted_schedule,
                specialistName: application.specialist_name,
                remarks: application.remarks,
            }));
        },
        setPagination: (state: ExportStates, { payload }) => {
            state.pagination = {
                count: payload.count,
                currentPage: payload.current_page,
                lastPage: payload.last_page,
                perPage: payload.per_page,
                total: payload.total,
            };
        },
        resetExportDaidoEmployee: (state: ExportStates) => {
            state.downloadDaidoEmployee.data = null;
            state.downloadDaidoEmployee.loading = false;
            state.downloadDaidoEmployee.success = false;
        },
        resetExportCorporate: (state: ExportStates) => {
            state.downloadCorporate.data = null;
            state.downloadCorporate.success = false;
            state.downloadCorporate.loading = false;
        },
        resetExportKouteki: (state: ExportStates) => {
            state.downloadKouteki.koutekiData = null;
            state.downloadKouteki.koutekiSuccess = false;
            state.downloadKouteki.koutekiLoading = false;
        },
        resetExportKisoku: (state: ExportStates) => {
            state.downloadKisoku.kisokuData = null;
            state.downloadKisoku.kisokuSuccess = false;
            state.downloadKisoku.kisokuLoading = false;
        },
        resetEmployees: (state: ExportStates) => {
            state.daidoEmployees = [];
        },
        resetCompanies: (state: ExportStates) => {
            state.companies = [];
        },
        resetKouteki: (state: ExportStates) => {
            state.applications = [];
        },
        resetKisoku: (state: ExportStates) => {
            state.applications = [];
        },
        resetPagination: (state: ExportStates) => {
            state.pagination = {} as Pagination;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(downloadDaidoEmployeeList.pending, (state: ExportStates) => {
                state.downloadDaidoEmployee.loading = true;
            })
            .addCase(downloadDaidoEmployeeList.rejected, (state: ExportStates) => {
                state.downloadDaidoEmployee.success = false;
                state.downloadDaidoEmployee.loading = false;
            })
            .addCase(downloadDaidoEmployeeList.fulfilled, (state: ExportStates) => {
                state.downloadDaidoEmployee.loading = false;
                state.downloadDaidoEmployee.success = true;
            })
            .addCase(downloadCorporateList.pending, (state: ExportStates) => {
                state.downloadCorporate.loading = true;
            })
            .addCase(downloadCorporateList.rejected, (state: ExportStates) => {
                state.downloadCorporate.success = false;
                state.downloadCorporate.loading = false;
            })
            .addCase(downloadCorporateList.fulfilled, (state: ExportStates) => {
                state.downloadCorporate.loading = false;
                state.downloadCorporate.success = true;
            })
            .addCase(downloadKoutekiList.pending, (state: ExportStates) => {
                state.downloadKouteki.koutekiLoading = true;
            })
            .addCase(downloadKoutekiList.rejected, (state: ExportStates) => {
                state.downloadKouteki.koutekiSuccess = false;
                state.downloadKouteki.koutekiLoading = false;
            })
            .addCase(downloadKoutekiList.fulfilled, (state: ExportStates) => {
                state.downloadKouteki.koutekiLoading = false;
                state.downloadKouteki.koutekiSuccess = true;
            })
            .addCase(downloadKisokuList.pending, (state: ExportStates) => {
                state.downloadKisoku.kisokuLoading = true;
            })
            .addCase(downloadKisokuList.rejected, (state: ExportStates) => {
                state.downloadKisoku.kisokuLoading = false;
                state.downloadKisoku.kisokuSuccess = false;
            })
            .addCase(downloadKisokuList.fulfilled, (state: ExportStates) => {
                state.downloadKisoku.kisokuSuccess = true;
                state.downloadKisoku.kisokuLoading = false;
            })
            .addMatcher(
                isAnyOf(
                    getDaidoEmployees.pending,
                    getCompanies.pending,
                    getKouteki.pending,
                    getKisoku.pending,
                ), (state: ExportStates) => {
                    state.loading = true;
                })
            .addMatcher(
                isAnyOf(
                    getDaidoEmployees.rejected,
                    getDaidoEmployees.fulfilled,
                    getCompanies.rejected,
                    getCompanies.fulfilled,
                    getKouteki.rejected,
                    getKouteki.fulfilled,
                    getKisoku.rejected,
                    getKisoku.fulfilled,
                ), (state: ExportStates) => {
                    state.loading = false;
                });
    },
});

export const {
    setExportDaidoEmployee,
    setExportCorporate,
    setExportKouteki,
    setExportKisoku,
    setDaidoEmployeeDetails,
    setCompanies,
    setApplications,
    setPagination,
    resetExportDaidoEmployee,
    resetExportCorporate,
    resetExportKouteki,
    resetExportKisoku,
    resetEmployees,
    resetCompanies,
    resetKouteki,
    resetKisoku,
    resetPagination,
} = exportSlice.actions;
export const selectExport = (state: RootState) => state.export;
