import { getAxiosClientWithToken } from './index';
import { ScheduleConsultationRequest } from 'app/slice/consultationSlice';

export type GetApplicationsRequest = {
    keywords?: string,
    page?: number,
    status?: number
}

/**
 * Function to call API and get a list of Applications belonging to the logged-in User
 **/
export const getApplicationsApi = (request: GetApplicationsRequest) => {
    const restClient = getAxiosClientWithToken();

    // Remove keywords key if empty
    if (request.keywords === undefined || request.keywords === '') {
        delete request['keywords'];
    }

    // Remove per_page key if empty or if it has an invalid value
    if (request.page === undefined || request.page <= 0) {
        delete request['page'];
    }

    // Remove status key if empty or invalid
    if (request.status === undefined || request.status === -1) {
        delete request.status;
    }

    return restClient.get(`/company/applications`, { params: request });
};

/**
 * Function to call API and get a list of Application Status Types
 **/
export const getApplicationStatusTypesApi = () => {
    const restClient = getAxiosClientWithToken();
    return restClient.get(`/company/status-types/application`);
};

/**
 * Function to call API and set the Consultation Schedule for a given Application
 */
export const scheduleConsultationApi = (request: ScheduleConsultationRequest) => {
    const restClient = getAxiosClientWithToken();
    return restClient.post(`/daido/applications/${request.uuid}/schedule`, request);
};

/**
 * Function to call API and get a specific Application that belongs to the current user
 **/
export const getSpecialistProfileApi = (uuid: string, specialist: string) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get(`/daido/applications/${uuid}/specialist/${specialist}`);
};
