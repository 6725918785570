import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';

import {RootState} from 'app/store';
import {getWorkRegulationConsultationList} from "api/kouteki";
import {getErrorMessage} from "api";
import {FetchWorkRegulationConsultationListRequest} from "types";

export type WorkRegulationState = {
    loading: boolean,
    success: boolean,
    workRegulations: WorkRegulation[],
};

export type WorkRegulation = {
    title: string,
    requestDate: string,
    interviewDate: string,
    status: Status,
    corpUserId: string,
    uuid: string,
}

type WorkRegulationData = {
    title: string,
    request_date: string,
    interview_date: string,
    status: Status,
    corp_user_id: string,
    uuid: string,
}

type Status = {
    content: string;
    color: string;
}

export const fetchWorkRegulationConsultationListOfEmployee = createAsyncThunk('daido/employee/kouteki/meeting',
    async (params: FetchWorkRegulationConsultationListRequest, {dispatch, rejectWithValue}) => {
        try {
            if (params.status === -1 || params.status === undefined) {
                delete params.status;
            }
            let response = await getWorkRegulationConsultationList(params);
            const {data = [], success} = response.data;
            if (success) {
                dispatch(setKoutekiMeetingsData(data as WorkRegulationData[]));
                return true;
            }

            return rejectWithValue(false);
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    });

export const workRegulationSlice = createSlice({
    name: 'employee-work-regulation',
    initialState: {
        loading: false,
        success: false,
        workRegulations: [],
    } as WorkRegulationState,
    reducers: {
        setKoutekiMeetingsData: (state: WorkRegulationState, {payload}: {payload: WorkRegulationData[]}) => {
            state.workRegulations = payload.map(workRegulation => ({
                title: workRegulation.title,
                requestDate: workRegulation.request_date,
                interviewDate: workRegulation.interview_date,
                status: workRegulation.status,
                corpUserId: workRegulation.corp_user_id,
                uuid: workRegulation.uuid,
            }));
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchWorkRegulationConsultationListOfEmployee.pending, (state: WorkRegulationState) => {
            state.loading = true;
        });
        builder.addCase(fetchWorkRegulationConsultationListOfEmployee.fulfilled, (state: WorkRegulationState) => {
            state.loading = false;
            state.success = true;
        });
        builder.addCase(fetchWorkRegulationConsultationListOfEmployee.rejected, (state: WorkRegulationState) => {
            state.loading = false;
            state.success = false;
            state.success = false;
            state.workRegulations = [];
        });
    },
});

export const workRegulationSelector = (state: RootState) => state.workRegulation;
export const {setKoutekiMeetingsData} = workRegulationSlice.actions;
