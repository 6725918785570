import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';

import {RootState} from 'app/store';
import {FetchKisokuMeetingRequest} from "types";
import {getErrorMessage} from "api";
import {getKisokukiMeetings} from "api/kisoku";
import {Label} from "app/slice/employeeKoutekiSlice";

export type KisokuMeetingState = {
    loading: boolean,
    success: boolean,
    total: number,
    perPage: number,
    currentPage: number,
    lastPage: number,
    kisokuMeetings: KisokuMeeting[],
};

export type KisokuMeeting = {
    title?: string,
    scheduleApplied: string,
    scheduleMeeting: string,
    status: number,
    corporateName: string,
    label: Label,
    corpUserId: string,
    uuid: string,
}

type KisokuMeetingData = {
    title?: string,
    schedule_applied: string,
    schedule_meeting: string,
    status: number,
    corporate_name: string,
    label: Label,
    corp_user_id: string,
    uuid: string,
}

export const fetchKisukuMeetings = createAsyncThunk('daido/kisoku/meeting',
    async (params: FetchKisokuMeetingRequest, {dispatch, rejectWithValue}) => {
        try {
            if (params.status === -1 || params.status === undefined) {
                delete params.status;
            }
            const response = await getKisokukiMeetings(params);
            const {data = [], pagination = {}, success} = response.data;
            if (success) {
                dispatch(setKisokuMeetingsData({data, pagination}));
                return true;
            }

            return rejectWithValue(false);
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    });

export const kisokuMeetingSlice = createSlice({
    name: 'kisoku-meetting',
    initialState: {
        loading: false,
        success: false,
        total: 0,
        perPage: 0,
        currentPage: 0,
        lastPage: 0,
        kisokuMeetings: [],
    } as KisokuMeetingState,
    reducers: {
        setKisokuMeetingsData: (state: KisokuMeetingState, {payload}) => {
            state.total = payload.pagination.total;
            state.perPage = payload.pagination.per_page;
            state.currentPage = payload.pagination.current_page;
            state.lastPage = payload.pagination.last_page;
            state.kisokuMeetings = [...payload.data]?.map((record: KisokuMeetingData) => ({
                title: record?.title,
                scheduleApplied: record?.schedule_applied,
                scheduleMeeting: record?.schedule_meeting,
                status: record?.status,
                corporateName: record?.corporate_name,
                label: record?.label,
                corpUserId: record?.corp_user_id,
                uuid: record?.uuid,
            }));
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchKisukuMeetings.pending, (state: KisokuMeetingState) => {
            state.loading = true;
        });
        builder.addCase(fetchKisukuMeetings.fulfilled, (state: KisokuMeetingState) => {
            state.loading = false;
            state.success = true;
        });
        builder.addCase(fetchKisukuMeetings.rejected, (state: KisokuMeetingState) => {
            state.loading = false;
            state.success = false;
            state.total = 0;
            state.perPage = 0;
            state.currentPage = 0;
            state.lastPage = 0;
            state.kisokuMeetings = [];
        });
    },
});

export const kisokuMeetingSelector = (state: RootState) => state.kisokuMeeting;
export const {setKisokuMeetingsData} = kisokuMeetingSlice.actions;
