import { createAdminForBranchCode, deleteForBranch, putForBranch } from 'api/branches';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { RootState } from 'app/store';
import { fetchBranchDepartments } from 'api/resources';
import { fetchEmployeesByDepartmentCode, getBranches } from 'api/employee';
import { getErrorMessage } from 'api';
import { GetBranchRequest } from 'types';

export type Branch = {
    branchId: number;
    code: string;
    name: string;
};

export type BranchResponse = {
    id: number;
    code: string;
    name: string;
};

export type CreateAdminPayload = {
    branchCode: string;
    branchName: string;
    password: string;
};

export type PutBranchPayload = {
    oldBranchCode: string;
    newBranchCode: string;
    branchName: string;
    password: string;
};

export type Department = {
    id: number;
    code: string;
    name: string;
};

type Profile = {
    firstname: string;
    lastname: string;
};

type User = {
    profile: Profile;
};

export type EmployeeResponse = {
    id: number;
    code: string;
    user: User;
};

export type DeptEmployee = {
    id: number;
    code: string;
    name: string;
};

type StateProcess = {
    success: boolean;
    error: boolean;
    message: string;
};

type BranchStateType = {
    branches: Branch[];
    departments: Department[];
    employees: DeptEmployee[];
    branchesWithoutAdmin: Branch[];
    didCreateANewBranch: boolean;
    isRequestError: boolean;
    isRequestSuccess: boolean;
    isRequesting: boolean;
    messageFromRequest: string;
    didUpdateBranch: boolean;
    isDeleteSuccess: StateProcess;
    message: string;
    pagination: Pagination
};

export type Pagination = {
    total: number,
    perPage: number,
    currentPage: number,
    lastPage: number,
}

const branchState: BranchStateType = {
    branches: [] as Branch[],
    departments: [] as Department[],
    employees: [] as DeptEmployee[],
    branchesWithoutAdmin: [] as Branch[],
    didCreateANewBranch: false,
    isRequestError: false,
    message: '',
    isRequestSuccess: false,
    isRequesting: false,
    messageFromRequest: '',
    didUpdateBranch: false,
    isDeleteSuccess: {
        success: false,
        error: false,
        message: '',
    } as StateProcess,
    pagination: {} as Pagination,
};

export const fetchAllBranches = createAsyncThunk(
    'daido/branches',
    async (params: GetBranchRequest, { dispatch, rejectWithValue }) => {
        try {
            dispatch(resetBranchState());
            let response = await getBranches(params);
            const { data = {}, success } = response.data;
            if (success) {
                dispatch(setBranchData(data));
                dispatch(setPagination(response.data));
                return true;
            }

            return rejectWithValue('Server error.');
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    },
);

export const createAdminForBranch = createAsyncThunk(
    'daido/branch/admin/create',
    async (payload: CreateAdminPayload, { dispatch, rejectWithValue }) => {
        const { branchCode, branchName, password } = payload;
        try {
            let response = await createAdminForBranchCode(branchCode, branchName, password);
            const { success } = response.data;
            if (success) {
                dispatch(setDidCreateANewBranch(true));
                return true;
            }

            return rejectWithValue('Server error.');
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    },
);

export const updateBranch = createAsyncThunk(
    'daido/branch/admin/update',
    async (payload: PutBranchPayload, { dispatch, rejectWithValue }) => {
        const { oldBranchCode, newBranchCode, branchName, password } = payload;
        try {
            let response = await putForBranch(oldBranchCode, newBranchCode, branchName, password);
            const { success } = response.data;
            if (success) {
                dispatch(setDidUpdateBranch(true));
                return true;
            }

            return rejectWithValue('Server error.');
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    },
);

export const deleteBranch = createAsyncThunk(
    'daido/branch/admin/delete',
    async (payload: string, { dispatch, rejectWithValue }) => {
        try {
            let response = await deleteForBranch(payload);
            const { success } = response.data;
            if (success) {
                dispatch(setIsDeleteSuccess(true));
                return true;
            }

            return rejectWithValue('Server error.');
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    },
);

export const fetchAllBranchDepartments = createAsyncThunk(
    'daido/branches/departments',
    async (code: string, { dispatch, rejectWithValue }) => {
        try {
            const response = await fetchBranchDepartments(code);
            const { data, success } = response.data;
            if (success) {
                dispatch(setDepartments(data));
                return true;
            }

            return rejectWithValue('Server error.');
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    },
);

export const fetchAllDepartmentsEmployees = createAsyncThunk(
    'daido/branches/employees',
    async (id: number, { dispatch, rejectWithValue }) => {
        try {
            const response = await fetchEmployeesByDepartmentCode(id);
            const { data, success } = response.data;
            if (success) {
                dispatch(setDepartmentEmployee(data));
                return true;
            }
            return rejectWithValue('Server error.');
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    },
);

export const branchSlice = createSlice({
    name: 'branch',
    initialState: branchState,
    reducers: {
        setBranchData: (state, { payload }) => {
            const branchList = [] as Branch[];
            payload?.forEach((branch: BranchResponse) => {
                branchList.push({
                    branchId: branch.id,
                    code: branch.code,
                    name: branch.name,
                });
            });
            state.branches = branchList;
        },
        setDepartments: (state, { payload }) => {
            state.departments = payload;
        },
        setDepartmentEmployee: (state, { payload }) => {
            state.employees = [...payload].map((employee: EmployeeResponse) => ({
                id: employee.id,
                code: employee.code,
                name: `${employee?.user?.profile?.lastname ?? ''} ${employee?.user?.profile?.firstname ?? ''}`,
            }));
        },
        setDidCreateANewBranch: (state, action) => {
            state.didCreateANewBranch = action.payload;
        },
        setPagination: (state, { payload }) => {
            state.pagination = {
                total: payload.total,
                perPage: payload.per_page,
                currentPage: payload.current_page,
                lastPage: payload.last_page,
            };
        },
        resetBranchState: (state) => {
            state.branches = [];
        },
        resetDidCreateANewBranch: (state) => {
            state.didCreateANewBranch = false;
        },

        resetRequestState: (state) => {
            state.isRequestError = false;
            state.isRequestSuccess = false;
            state.isRequesting = false;
            state.messageFromRequest = '';
            state.message = '';
        },

        setDidUpdateBranch: (state, action) => {
            state.didUpdateBranch = action.payload;
        },

        setIsDeleteSuccess: (state, action) => {
            state.isDeleteSuccess.success = action.payload;
        },

        resetDidUpdateBranch: (state) => {
            state.didUpdateBranch = false;
        },

        resetIsDeleteSuccess: (state) => {
            state.isDeleteSuccess = {
                success: false,
                error: false,
                message: 'Branch was successfully deleted',
            };
        },
    },
    extraReducers: (builder) => {
        builder.addCase(deleteBranch.rejected, (state: BranchStateType, action: any) => {
            state.isDeleteSuccess = {
                success: false,
                error: true,
                message: action.payload,
            };
        });
        builder.addCase(createAdminForBranch.rejected, (state: BranchStateType, action: any) => {
            state.message = action.payload;
            state.isRequestError = true;
            state.isRequestSuccess = false;
        });
        builder.addCase(fetchAllBranches.pending, (state: BranchStateType) => {
            state.pagination = {} as Pagination;
            state.isRequesting = true;
        });
        builder.addCase(fetchAllBranches.fulfilled, (state: BranchStateType) => {
            state.isRequesting = false;
        });
        builder.addCase(fetchAllBranches.rejected, (state: BranchStateType) => {
            state.isRequesting = false;
        });
    },
});

export const {
    setBranchData,
    setDepartments,
    setDepartmentEmployee,
    setPagination,
    resetBranchState,
    resetRequestState,
    setDidCreateANewBranch,
    resetDidCreateANewBranch,
    resetDidUpdateBranch,
    setDidUpdateBranch,
    setIsDeleteSuccess,
    resetIsDeleteSuccess,
} = branchSlice.actions;
export const branchSelector = (state: RootState) => state.branch;
