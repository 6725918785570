import { getAxiosClientWithToken } from 'api';
import {
    FetchKotekiMeetingEmployeeDetailRequest,
    FetchKotekiMeetingRequest,
    FetchWorkRegulationConsultationListRequest
} from "types";

export const getKoutekiMeetings = (params: FetchKotekiMeetingRequest) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get('/daido/koteki-meeting', {params});
};

export const getPublicSupportConsultationList = ({employeeCode, keywords, status, page}: FetchKotekiMeetingEmployeeDetailRequest) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get(`/daido/applications/company/${employeeCode}/koteki-meeting`,
        {
            params: {
                keywords,
                status,
                page,
            }
        }
    );
};

export const getWorkRegulationConsultationList = ({employeeCode, keyword, status}: FetchWorkRegulationConsultationListRequest) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get(`/daido/work-regulation-meetings/${employeeCode}`, {
        params: {
            keyword,
            status,
        }
    });
};
