import { getAxiosClientWithToken } from './index';
import {
    BranchDetailRequest,
    DepartmentBranchQuery,
    DepartmentDetailQuery,
    DepartmentQuery,
    RegisterDepartmentFieldData,
    UpdateDepartmentRequest
} from "../types";

export const getDepartmentByFilter = (params?: DepartmentQuery) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get('/daido/departments', {params});
};

export const getSuggestBranches = (params?: DepartmentBranchQuery) => {
    const restClient = getAxiosClientWithToken();

    return restClient.get('/daido/departments/branches', {params});
};

export const getBranchDetail = ({branchCode}: BranchDetailRequest) => {
    const restClient = getAxiosClientWithToken();

    return restClient.get(`/daido/branches/${branchCode}`);
};

export const registerDepartment = (params: RegisterDepartmentFieldData) => {
    const restClient = getAxiosClientWithToken();

    return restClient.post('/daido/departments', params);
};

export const getDepartmentDetail = ({departmentId}: DepartmentDetailQuery) => {
    const restClient = getAxiosClientWithToken();

    return restClient.get(`/daido/departments/${departmentId}`);
};

export const updateDepartment = ({id, department_name, department_code, branch_code}: UpdateDepartmentRequest) => {
    const restClient = getAxiosClientWithToken();

    return restClient.put(`/daido/departments/${id}`, {department_name, department_code, branch_code});
};

export const deleteDepartment = ({departmentId}: DepartmentDetailQuery) => {
    const restClient = getAxiosClientWithToken();

    return restClient.delete(`/daido/departments/${departmentId}`);
};
