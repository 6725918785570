import { getAxiosClientWithToken } from './index';

export const createAdminForBranchCode = (branchCode: string, branchName: string, password: string) => {
    const restClient = getAxiosClientWithToken();

    return restClient.post('/daido/branch', {
        branch_code: branchCode,
        branch_name: branchName,
        password: password
    });
};

export const putForBranch = (
    old_branch_code: string,
    new_branch_code: string,
    branch_name: string,
    password: string
) => {
    const restClient = getAxiosClientWithToken();

    return restClient.put('/daido/branch', {
        old_branch_code,
        new_branch_code,
        branch_name,
        password
    });
};

export const deleteForBranch = (branch_code: string) => {
    const restClient = getAxiosClientWithToken();
    return restClient.delete('/daido/branch', {
        data: {
            branch_code
        }
    });
};
