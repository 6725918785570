import { getAxiosClientWithToken } from 'api';
import {CancellationConsultationRequest} from "../app/slice/consultationSlice";
import qs from "qs";

export type GetConsultationDetailRequest = {
    customerUuid: string,
    applicationUuid: string,
}

export const getConsultationDetail = ({customerUuid, applicationUuid}: GetConsultationDetailRequest) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get(`/daido/applications/company/${customerUuid}/detail/${applicationUuid}`);
};

export const cancellationConsultation = ({applicationUuid, reason}: CancellationConsultationRequest) => {
    const restClient = getAxiosClientWithToken();
    return restClient.patch(`/daido/application/${applicationUuid}/cancel`, qs.stringify({reason}), {
        headers: {
            'Cache-Control': 'no-cache',
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    });
};

