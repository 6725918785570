import { DiagnosticHistoryListRequest, DiagnosticHistoryRequest } from 'app/slice/diagnosticHistorySlice';
import { getAxiosClientWithToken } from './index';

export const getDiagnosticHistoryListApi = (params: DiagnosticHistoryListRequest) => {
    const { page } = params;
    const restClient = getAxiosClientWithToken();
    return restClient.get('/daido/diagnostic', {
        params: { page },
    });
};

export const getDiagnosticHistoryListKisokuApi = (params: DiagnosticHistoryListRequest) => {
    const { page } = params;
    const restClient = getAxiosClientWithToken();
    return restClient.get('/daido/diagnostic/kisoku', {
        params: { page },
    });
};

export const downloadDiagnosticHistoryListApi = () => {
    const restClient = getAxiosClientWithToken();
    return restClient.get('/daido/diagnostic/export', {
        responseType: 'arraybuffer',
    });
};

export const downloadDiagnosticHistoryKisokuListApi = () => {
    const restClient = getAxiosClientWithToken();
    return restClient.get('/daido/diagnostic/export/kisoku', {
        responseType: 'arraybuffer',
    });
};

export const getDiagnosticHistoryApi = (params: DiagnosticHistoryRequest) => {
    const { year, month, page } = params;
    const restClient = getAxiosClientWithToken();
    return restClient.get(`/daido/diagnostic/${year}/${month}`, {
        params: { page },
    });
};

export const downloadDiagnosticHistoryApi = (params: DiagnosticHistoryRequest) => {
    const { year, month } = params;
    const restClient = getAxiosClientWithToken();
    return restClient.get(`/daido/diagnostic/${year}/${month}/export`, {
        responseType: 'arraybuffer',
    });
};
