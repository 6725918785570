import { getAxiosClientWithToken } from './index';
import { KisokuTemplateListRequest, KisokuTemplateRequest } from 'app/slice/kisokuTemplateSlice';

export const getKisokuTemplateListApi = (params: KisokuTemplateListRequest) => {
    const { page } = params;
    const restClient = getAxiosClientWithToken();
    return restClient.get('/daido/kisoku-template', {
        params: { page },
    });
};

export const downloadKisokuTemplateListApi = () => {
    const restClient = getAxiosClientWithToken();
    return restClient.get('/daido/kisoku-template/export', {
        responseType: 'arraybuffer',
    });
};

export const getKisokuTemplateApi = (params: KisokuTemplateRequest) => {
    const { year, month, page } = params;
    const restClient = getAxiosClientWithToken();
    return restClient.get(`/daido/kisoku-template/${year}/${month}`, {
        params: { page },
    });
};

export const downloadKisokuTemplateApi = (params: KisokuTemplateRequest) => {
    const { year, month } = params;
    const restClient = getAxiosClientWithToken();
    return restClient.get(`/daido/kisoku-template/${year}/${month}/export`, {
        responseType: 'arraybuffer',
    });
};
