import { Button, Form, Input } from 'antd';
import React, { useEffect, useState } from 'react';

import ChangePasswordSuccess from 'pages/Dashboard/Management/ChangePasswordSuccess';
import { authSelector } from 'app/slice/authSlice';
import { changePassword, companySelector } from 'app/slice/companySlice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { validatePasswordConfirmation } from 'lib/utils';

type Props = {
    isPasswordSuccess: boolean;
    setClose: () => void;
    setIsPasswordSuccess: (value: boolean) => void;
};

const rules = {
    newPassword: [{ required: true, message: '新しいパスワードを入力してください' }],
    newPasswordConfirmation: [
        { required: true, message: '新しいパスワード（確認用）をご入力ください。' },
        validatePasswordConfirmation('new_password', 'パスワードは一致していません。'),
    ],
};

const ChangePasswordRequirement = (props: Props) => {
    const { setIsPasswordSuccess, setClose, isPasswordSuccess } = props;
    const [form] = Form.useForm();
    const dispatch = useAppDispatch();
    const { status, loading } = useAppSelector(companySelector);
    const [invalidOldPassword, setInvalidOldPassword] = useState(false);
    const resetInvalidOldPassword = () => setInvalidOldPassword(false);

    const onSubmit = async (values: any) => {
        const { current_password, new_password, new_password_confirmation } = values;
        await dispatch(changePassword({ current_password, new_password, new_password_confirmation }));
    };

    useEffect(() => {
        if (status === 'success') {
            setIsPasswordSuccess(true);
            form.resetFields();
        } else if (status === 'failed') {
            setInvalidOldPassword(true);
        }
    }, [form, setIsPasswordSuccess, status]);

    const onCloseSuccess = () => {
        setClose();
    };

    const validateOldPassword = () => {
        if (invalidOldPassword) {
            return Promise.reject('現在のパスワードが違います');
        }
        return Promise.resolve();
    };

    useEffect(() => {
        if (form.getFieldValue('current_password')) {
            form.validateFields(['current_password']);
        }
    }, [form, invalidOldPassword]);

    return (
        <>
            {isPasswordSuccess === false ? (
                <Form form={form} onFinish={onSubmit} layout='vertical' autoComplete='off'>
                    <Form.Item
                        name='current_password'
                        label='現在のパスワード'
                        rules={[
                            { required: true, message: '現在のパスワードを入力してください' },
                            { validator: validateOldPassword },
                        ]}
                    >
                        <Input.Password onChange={resetInvalidOldPassword} />
                    </Form.Item>
                    <Form.Item
                        name='new_password'
                        label='新しいパスワード'
                        rules={rules.newPassword}
                    >
                        <Input.Password />
                    </Form.Item>
                    <Form.Item
                        name='new_password_confirmation'
                        label='新しいパスワード (確認用)'
                        rules={rules.newPasswordConfirmation}
                    >
                        <Input.Password />
                    </Form.Item>

                    <Button type='primary' htmlType='submit' className='w-100' loading={loading}>
                        確定
                    </Button>
                </Form>
            ) : (
                <ChangePasswordSuccess onCloseSuccess={onCloseSuccess} />
            )}
        </>
    );
};

export default ChangePasswordRequirement;
