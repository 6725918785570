import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { RootState } from 'app/store';
import { authenticate } from 'api/auth';
import { getErrorMessage } from 'api';
import { setAuth } from 'app/slice/authSlice';

export type LoginState = {
    success: boolean | null;
    message: string;
    loading: boolean;
    error: boolean;
};

export type LoginRequest = {
    email: string;
    password: string;
};

/**
 * calls login API
 **/
export const login = createAsyncThunk('users/login', async ({ email, password }: LoginRequest, {
    dispatch,
    rejectWithValue,
}) => {
    try {
        let response = await authenticate(email, password);
        const { data = {} } = response;

        if (data.success === true) {
            dispatch(setAuth(data));
            return true;
        }

        return rejectWithValue('Server error.');
    } catch (error: any) {
        return rejectWithValue(getErrorMessage(error));
    }
});

export const loginSlice = createSlice({
    name: 'login',
    initialState: {
        success: false,
        message: '',
        loading: false,
        error: false,
    } as LoginState,
    reducers: {
        reset: (state: LoginState) => {
            state.success = false;
            state.message = '';
            state.loading = false;
            state.error = false;
        },
    },
    extraReducers: (builder) => {
        // login action pending
        builder.addCase(login.pending, (state: LoginState) => {
            state.loading = true;
        });
        // login action rejected
        builder.addCase(login.rejected, (state: LoginState, action) => {
            state.loading = false;
            state.success = false;
            state.error = true;
            state.message = 'IDまたはパスワードが違います。';
        });
        // login action fulfilled
        builder.addCase(login.fulfilled, (state: LoginState) => {
            state.loading = false;
            state.success = true;
        });
    },
});

export const loginSelector = (state: RootState) => state.login;
export const { reset } = loginSlice.actions;
