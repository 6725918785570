import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import {
    downloadDiagnosticHistoryApi,
    downloadDiagnosticHistoryListApi,
    downloadDiagnosticHistoryKisokuListApi,
    getDiagnosticHistoryApi,
    getDiagnosticHistoryListApi,
    getDiagnosticHistoryListKisokuApi
} from 'api/diagnosticExport';

type GetDiagnosticHistoryListResponse = {
    number_of_corp_users: number;
    diagnoses_from_corp_users: number;
    total_diagnoses: number;
    display_date: string;
};

type GetDiagnosticHistoryResponse = {
    created_at: number;
    company_name: string;
    email: number;
    funds: string[];
};

type DiagnosticHistoryState = {
    success: boolean | null;
    diagnoseList: {
        data: DiagnosticHistoryList[],
        pagination: Pagination
    };
    diagnose: {
        data: DiagnosticHistory[],
        pagination: Pagination
    };
    loading: boolean;
    downloadHistory: DownloadHistory;
};

type DownloadHistory = {
    data: Blob | null;
    loading: boolean;
    success: boolean;
}

export type DiagnosticHistoryListRequest = {
    page?: number;
};

export type DiagnosticHistoryRequest = {
    page?: number;
    year: string;
    month: string;
};

export type DiagnosticHistoryList = {
    corpUserCount: number;
    diagnosisCorpUsers: number;
    totalDiagnoses: number;
    displayDate: string;
};

export type DiagnosticHistory = {
    createdAt: number;
    companyName: string;
    email: number;
    funds: string[];
};

export type Pagination = {
    total: number,
    perPage: number,
    currentPage: number,
    lastPage: number,
}

export const getDiagnosticHistoryList = createAsyncThunk(
    'diagnostic/all',
    async (params: DiagnosticHistoryListRequest, { dispatch, rejectWithValue }) => {
        try {
            const response = await getDiagnosticHistoryListApi(params);
            dispatch(setDiagnosticList(response.data));
            dispatch(setDiagnosticListPagination(response.data));
            return response.data;
        } catch (err) {
            return rejectWithValue(false);
        }
    },
);

export const getDiagnosticHistoryKisokuList = createAsyncThunk(
    'diagnostic/all',
    async (params: DiagnosticHistoryListRequest, { dispatch, rejectWithValue }) => {
        try {
            const response = await getDiagnosticHistoryListKisokuApi(params);
            dispatch(setDiagnosticList(response.data));
            dispatch(setDiagnosticListPagination(response.data));
            return response.data;
        } catch (err) {
            return rejectWithValue(false);
        }
    },
);

export const downloadDiagnosticHistoryList = createAsyncThunk(
    'diagnostic/all/export',
    async (_, { dispatch, rejectWithValue }) => {
        try {
            const response = await downloadDiagnosticHistoryListApi();
            dispatch(setDownloadDiagnosticList(response.data));
            return response.data;
        } catch (err) {
            return rejectWithValue(false);
        }
    },
);

export const downloadDiagnosticHistoryKisokuList = createAsyncThunk(
    'diagnostic/kisoku/export',
    async (_, { dispatch, rejectWithValue }) => {
        try {
            const response = await downloadDiagnosticHistoryKisokuListApi();
            dispatch(setDownloadDiagnosticList(response.data));
            return response.data;
        } catch (err) {
            return rejectWithValue(false);
        }
    },
);


export const getDiagnosticHistory = createAsyncThunk(
    'diagnostic/get',
    async (params: DiagnosticHistoryRequest, { dispatch, rejectWithValue }) => {
        try {
            const response = await getDiagnosticHistoryApi(params);
            dispatch(setDiagnostic(response.data));
            dispatch(setDiagnosticPagination(response.data));
            return response.data;
        } catch (err) {
            return rejectWithValue(false);
        }
    },
);

export const downloadDiagnosticHistory = createAsyncThunk(
    'diagnostic/get/export',
    async (params: DiagnosticHistoryRequest, { dispatch, rejectWithValue }) => {
        try {
            const response = await downloadDiagnosticHistoryApi(params);
            dispatch(setDownloadDiagnostic(response.data));
            return response.data;
        } catch (err) {
            return rejectWithValue(false);
        }
    },
);

export const diagnosticHistorySlice = createSlice({
    name: 'diagnostic-history',
    initialState: {
        success: null,
        diagnoseList: {
            data: [] as DiagnosticHistoryList[],
            pagination: {} as Pagination,
        },
        diagnose: {
            data: [] as DiagnosticHistory[],
            pagination: {} as Pagination,
        },
        loading: false,
        downloadHistory: {
            loading: false,
            success: false,
            data: null,
        } as DownloadHistory,
    } as DiagnosticHistoryState,
    reducers: {
        setDiagnosticList: (state, { payload }) => {
            const { data } = payload;

            state.diagnoseList.data = data.map((history: GetDiagnosticHistoryListResponse) => ({
                corpUserCount: history.number_of_corp_users,
                diagnosisCorpUsers: history.diagnoses_from_corp_users,
                totalDiagnoses: history.total_diagnoses,
                displayDate: history.display_date,
            }));
        },
        setDownloadDiagnosticList: (state: DiagnosticHistoryState, { payload }) => {
            state.downloadHistory.data = payload;
        },
        setDiagnostic: (state, { payload }) => {
            const { data } = payload;

            state.diagnose.data = data.map((history: GetDiagnosticHistoryResponse) => ({
                createdAt: history.created_at,
                companyName: history.company_name,
                email: history.email,
                funds: history.funds,
            }));
        },
        setDownloadDiagnostic: (state: DiagnosticHistoryState, { payload }) => {
            state.downloadHistory.data = payload;
        },
        setDiagnosticListPagination: (state, { payload }) => {
            state.diagnoseList.pagination = {
                total: payload.total,
                perPage: payload.per_page,
                currentPage: payload.current_page,
                lastPage: payload.last_page,
            };
        },
        setDiagnosticPagination: (state, { payload }) => {
            state.diagnose.pagination = {
                total: payload.total,
                perPage: payload.per_page,
                currentPage: payload.current_page,
                lastPage: payload.last_page,
            };
        },
        resetDownloadDiagnosticList: (state: DiagnosticHistoryState) => {
            state.downloadHistory.data = null;
            state.downloadHistory.loading = false;
            state.downloadHistory.success = false;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getDiagnosticHistoryList.fulfilled, (state: DiagnosticHistoryState) => {
            state.success = true;
            state.loading = false;
        });
        builder.addCase(getDiagnosticHistoryList.pending, (state: DiagnosticHistoryState) => {
            state.success = false;
            state.loading = true;
            state.diagnoseList.data = [];
        });
        builder.addCase(getDiagnosticHistoryList.rejected, (state: DiagnosticHistoryState) => {
            state.success = false;
            state.loading = false;
        });
        builder.addCase(getDiagnosticHistory.fulfilled, (state: DiagnosticHistoryState) => {
            state.success = true;
            state.loading = false;
        });
        builder.addCase(getDiagnosticHistory.pending, (state: DiagnosticHistoryState) => {
            state.success = false;
            state.loading = true;
            state.diagnose.data = [];
        });
        builder.addCase(getDiagnosticHistory.rejected, (state: DiagnosticHistoryState) => {
            state.success = false;
            state.loading = false;
        });
        builder.addCase(downloadDiagnosticHistoryList.pending, (state: DiagnosticHistoryState) => {
            state.downloadHistory.loading = true;
        });
        builder.addCase(downloadDiagnosticHistoryList.rejected, (state: DiagnosticHistoryState) => {
            state.downloadHistory.loading = false;
            state.downloadHistory.success = false;
        });
        builder.addCase(downloadDiagnosticHistoryList.fulfilled, (state: DiagnosticHistoryState) => {
            state.downloadHistory.success = true;
            state.downloadHistory.loading = false;
        });
        builder.addCase(downloadDiagnosticHistory.pending, (state: DiagnosticHistoryState) => {
            state.downloadHistory.loading = true;
        });
        builder.addCase(downloadDiagnosticHistory.rejected, (state: DiagnosticHistoryState) => {
            state.downloadHistory.loading = false;
            state.downloadHistory.success = false;
        });
        builder.addCase(downloadDiagnosticHistory.fulfilled, (state: DiagnosticHistoryState) => {
            state.downloadHistory.success = true;
            state.downloadHistory.loading = false;
        });
        builder.addCase(downloadDiagnosticHistoryKisokuList.pending, (state: DiagnosticHistoryState) => {
            state.downloadHistory.loading = true;
        });
        builder.addCase(downloadDiagnosticHistoryKisokuList.rejected, (state: DiagnosticHistoryState) => {
            state.downloadHistory.loading = false;
            state.downloadHistory.success = false;
        });
        builder.addCase(downloadDiagnosticHistoryKisokuList.fulfilled, (state: DiagnosticHistoryState) => {
            state.downloadHistory.success = true;
            state.downloadHistory.loading = false;
        });
    },
});

export const selectDiagnosticHistory = (state: RootState) => state.diagnosticHistory;

export const {
    setDiagnosticList,
    setDownloadDiagnosticList,
    setDiagnostic,
    setDownloadDiagnostic,
    setDiagnosticListPagination,
    setDiagnosticPagination,
    resetDownloadDiagnosticList,
} = diagnosticHistorySlice.actions;

