import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
    fetchCapitalRanges,
    fetchCoverageTypes,
    fetchEmployeeCountRanges,
    fetchIndustries,
    fetchInsurancePlans,
    fetchPrefectures,
    fetchPurposes,
    fetchYearsEstablishedRanges,
} from 'api/resources';
import { RootState, store } from 'app/store';

export type Prefectures = {
    code: string,
    name: string
}

export type Industries = {
    code: string,
    name: string
}

export type InsurancePlans = {
    code: string,
    name: string
}

export type EmployeeCountRanges = {
    code: string,
    name: string
}

export type CoverageTypes = {
    code: string,
    name: string
}

export type CapitalRanges = {
    code: string,
    name: string
}

export type YearsEstablishedRanges = {
    code: string,
    name: string
}

export type Purposes = {
    code: string,
    name: string
}


/**
 * GET prefectures list
 **/
export const getPrefectures = createAsyncThunk(
    'resource/prefecture',
    async (data, { dispatch }) => {
        try {
            const response = await fetchPrefectures();
            dispatch(setPrefectures(response.data));
            return response.data;
        } catch (err) {
            return false;
        }
    },
);

/**
 * GET prefectures list
 **/
export const getIndustries = createAsyncThunk(
    'resource/industries',
    async (data, { dispatch }) => {
        try {
            const response = await fetchIndustries();
            dispatch(setIndustries(response.data));
            return response.data;
        } catch (err) {
            return false;
        }
    },
);

/**
 * GET insurance plans
 **/
export const getInsurancePlans = createAsyncThunk(
    'resource/insurancePlans',
    async (data, { dispatch }) => {
        try {
            const response = await fetchInsurancePlans();
            dispatch(setInsurancePlans(response.data));
            return response.data;
        } catch (err) {
            return false;
        }
    },
);

/**
 * GET employee count range
 **/
export const getEmployeeCountRanges = createAsyncThunk(
    'resource/employeeCountRanges',
    async (data, { dispatch }) => {
        try {
            const response = await fetchEmployeeCountRanges();
            dispatch(setEmployeeCountRanges(response.data));
            return response.data;
        } catch (err) {
            return false;
        }
    },
);

/**
 * GET employee count range
 **/
export const getCoverageTypes = createAsyncThunk(
    'resource/coverageTypes',
    async (data, { dispatch }) => {
        try {
            const response = await fetchCoverageTypes();
            dispatch(setCoverageTypes(response.data));
            return response.data;
        } catch (err) {
            return false;
        }
    },
);

/**
 * GET employee count range
 **/
export const getCapitalRanges = createAsyncThunk(
    'resource/capitalRanges',
    async (data, { dispatch }) => {
        try {
            const response = await fetchCapitalRanges();
            dispatch(setCapitalRanges(response.data));
            return response.data;
        } catch (err) {
            return false;
        }
    },
);

/**
 * GET employee count range
 **/
export const getYearsEstablishedRanges = createAsyncThunk(
    'resource/yearsEstablishedRanges',
    async (data, { dispatch }) => {
        try {
            const response = await fetchYearsEstablishedRanges();
            dispatch(setYearsEstablishedRanges(response.data));
            return response.data;
        } catch (err) {
            return false;
        }
    },
);

/**
 * GET employee count range
 **/
export const getPurposes = createAsyncThunk(
    'resource/purposes',
    async (data, { dispatch }) => {
        try {
            const response = await fetchPurposes();
            dispatch(setPurposes(response.data));
            return response.data;
        } catch (err) {
            return false;
        }
    },
);

export const resourcesSlice = createSlice({
    name: 'resources',
    initialState: {
        prefectures: [] as Prefectures[],
        industries: [] as Industries[],
        insurancePlans: [] as InsurancePlans[],
        employeeCountRanges: [] as EmployeeCountRanges[],
        coverageTypes: [] as CoverageTypes[],
        capitalRanges: [] as CapitalRanges[],
        yearsEstablishedRanges: [] as YearsEstablishedRanges[],
        purposes: [] as Purposes[],
    },
    reducers: {
        setPrefectures: (state, { payload }) => {
            state.prefectures = payload.data;
        },
        setIndustries: (state, { payload }) => {
            state.industries = payload.data;
        },
        setInsurancePlans: (state, { payload }) => {
            state.insurancePlans = payload.data;
        },
        setEmployeeCountRanges: (state, { payload }) => {
            state.employeeCountRanges = payload.data;
        },
        setCoverageTypes: (state, { payload }) => {
            state.coverageTypes = payload.data;
        },
        setCapitalRanges: (state, { payload }) => {
            state.capitalRanges = payload.data;
        },
        setYearsEstablishedRanges: (state, { payload }) => {
            state.yearsEstablishedRanges = payload.data;
        },
        setPurposes: (state, { payload }) => {
            state.purposes = payload.data;
        },
    },
});

export const getLoadedResources = () => {
    let resources = selectResources(store.getState());
    let list: string[] = [];

    for (let [key, value] of Object.entries(resources)) {
        if (value.length !== 0) {
            list.push(key);
        }
    }

    return list;
};

export const selectResources = (state: RootState) => state.resource;
export const {
    setPrefectures,
    setIndustries,
    setInsurancePlans,
    setEmployeeCountRanges,
    setCoverageTypes,
    setCapitalRanges,
    setYearsEstablishedRanges,
    setPurposes,
} = resourcesSlice.actions;
