import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import {
    downloadKisokuTemplateApi,
    downloadKisokuTemplateListApi,
    getKisokuTemplateApi,
    getKisokuTemplateListApi,
} from 'api/kisokuTemplate';

type GetKisokuTemplateListResponse = {
    count: number,
    display_date: string;
};

type GetKisokuTemplateResponse = {
    created_at: number;
    company_name: string;
    email: number;
    filename: string;
};

type KisokuTemplateState = {
    success: boolean | null;
    list: {
        data: KisokuTemplateList[],
        pagination: Pagination
    };
    data: {
        data: KisokuTemplate[],
        pagination: Pagination
    };
    loading: boolean;
    download: DownloadHistory;
};

type DownloadHistory = {
    data: Blob | null;
    loading: boolean;
    success: boolean;
}

export type KisokuTemplateListRequest = {
    page?: number;
};

export type KisokuTemplateRequest = {
    page?: number;
    year: string;
    month: string;
};

export type KisokuTemplateList = {
    count: number;
    displayDate: string;
};

export type KisokuTemplate = {
    createdAt: number;
    companyName: string;
    email: number;
    filename: string;
};

export type Pagination = {
    total: number,
    perPage: number,
    currentPage: number,
    lastPage: number,
}

export const getKisokuTemplateList = createAsyncThunk(
    'kisoku-template-log/list',
    async (params: KisokuTemplateListRequest, { dispatch, rejectWithValue }) => {
        try {
            const response = await getKisokuTemplateListApi(params);
            dispatch(setTemplateList(response.data));
            dispatch(setTemplateListPagination(response.data));
            return response.data;
        } catch (err) {
            return rejectWithValue(false);
        }
    },
);

export const downloadKisokuTemplateList = createAsyncThunk(
    'kisoku-template-log/list/download',
    async (_, { dispatch, rejectWithValue }) => {
        try {
            const response = await downloadKisokuTemplateListApi();
            dispatch(setDownloadTemplateList(response.data));
            return response.data;
        } catch (err) {
            return rejectWithValue(false);
        }
    },
);

export const getKisokuTemplate = createAsyncThunk(
    'kisoku-template-log/get',
    async (params: KisokuTemplateRequest, { dispatch, rejectWithValue }) => {
        try {
            const response = await getKisokuTemplateApi(params);
            dispatch(setTemplate(response.data));
            dispatch(setTemplatePagination(response.data));
            return response.data;
        } catch (err) {
            return rejectWithValue(false);
        }
    },
);

export const downloadKisokuTemplate = createAsyncThunk(
    'kisoku-template-log/get/download',
    async (params: KisokuTemplateRequest, { dispatch, rejectWithValue }) => {
        try {
            const response = await downloadKisokuTemplateApi(params);
            dispatch(setDownloadTemplate(response.data));
            return response.data;
        } catch (err) {
            return rejectWithValue(false);
        }
    },
);

export const kisokuTemplateSlice = createSlice({
    name: 'kisoku-template-log',
    initialState: {
        success: null,
        list: {
            data: [] as KisokuTemplateList[],
            pagination: {} as Pagination,
        },
        data: {
            data: [] as KisokuTemplate[],
            pagination: {} as Pagination,
        },
        loading: false,
        download: {
            loading: false,
            success: false,
            data: null,
        } as DownloadHistory,
    } as KisokuTemplateState,
    reducers: {
        setTemplateList: (state, { payload }) => {
            const { data } = payload;

            state.list.data = data.map((row: GetKisokuTemplateListResponse) => ({
                count: row.count,
                displayDate: row.display_date,
            }));
        },
        setDownloadTemplateList: (state: KisokuTemplateState, { payload }) => {
            state.download.data = payload;
        },
        setTemplate: (state, { payload }) => {
            const { data } = payload;

            state.data.data = data.map((history: GetKisokuTemplateResponse) => ({
                createdAt: history.created_at,
                companyName: history.company_name,
                email: history.email,
                filename: history.filename,
            }));
        },
        setDownloadTemplate: (state: KisokuTemplateState, { payload }) => {
            state.download.data = payload;
        },
        setTemplateListPagination: (state, { payload }) => {
            state.list.pagination = {
                total: payload.total,
                perPage: payload.per_page,
                currentPage: payload.current_page,
                lastPage: payload.last_page,
            };
        },
        setTemplatePagination: (state, { payload }) => {
            state.data.pagination = {
                total: payload.total,
                perPage: payload.per_page,
                currentPage: payload.current_page,
                lastPage: payload.last_page,
            };
        },
        resetDownloadTemplateList: (state: KisokuTemplateState) => {
            state.download.data = null;
            state.download.loading = false;
            state.download.success = false;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getKisokuTemplateList.fulfilled, (state: KisokuTemplateState) => {
            state.success = true;
            state.loading = false;
        });
        builder.addCase(getKisokuTemplateList.pending, (state: KisokuTemplateState) => {
            state.success = false;
            state.loading = true;
            state.list.data = [];
        });
        builder.addCase(getKisokuTemplateList.rejected, (state: KisokuTemplateState) => {
            state.success = false;
            state.loading = false;
        });
        builder.addCase(getKisokuTemplate.fulfilled, (state: KisokuTemplateState) => {
            state.success = true;
            state.loading = false;
        });
        builder.addCase(getKisokuTemplate.pending, (state: KisokuTemplateState) => {
            state.success = false;
            state.loading = true;
            state.data.data = [];
        });
        builder.addCase(getKisokuTemplate.rejected, (state: KisokuTemplateState) => {
            state.success = false;
            state.loading = false;
        });
        builder.addCase(downloadKisokuTemplateList.pending, (state: KisokuTemplateState) => {
            state.download.loading = true;
        });
        builder.addCase(downloadKisokuTemplateList.rejected, (state: KisokuTemplateState) => {
            state.download.loading = false;
            state.download.success = false;
        });
        builder.addCase(downloadKisokuTemplateList.fulfilled, (state: KisokuTemplateState) => {
            state.download.success = true;
            state.download.loading = false;
        });
        builder.addCase(downloadKisokuTemplate.pending, (state: KisokuTemplateState) => {
            state.download.loading = true;
        });
        builder.addCase(downloadKisokuTemplate.rejected, (state: KisokuTemplateState) => {
            state.download.loading = false;
            state.download.success = false;
        });
        builder.addCase(downloadKisokuTemplate.fulfilled, (state: KisokuTemplateState) => {
            state.download.success = true;
            state.download.loading = false;
        });
    },
});

export const selectKisokuTemplate = (state: RootState) => state.kisokuTemplate;

export const {
    setTemplateList,
    setDownloadTemplateList,
    setTemplate,
    setDownloadTemplate,
    setTemplateListPagination,
    setTemplatePagination,
    resetDownloadTemplateList,
} = kisokuTemplateSlice.actions;

