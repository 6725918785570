import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'app/store';
import { ReactNode } from 'react';

export type SuccessModalState = {
    isShow: boolean;
    title: string;
    message: string | ReactNode;
    withButton: boolean;
    image?: string;
    buttonLink?: string | number;
    buttonTitle?: string;
    origin?: string;
    onCancel?: () => void;
    onButtonClick?: () => void;
};

export const successModalSlice = createSlice({
    name: 'successModal',
    initialState: {
        image: '',
        title: '',
        message: '',
        withButton: false,
        buttonLink: '',
        buttonTitle: '',
        isShow: false,
        origin: '',
        onCancel: undefined,
        onButtonClick: undefined,
    } as SuccessModalState,
    reducers: {
        setSuccessState: (state: SuccessModalState, action: PayloadAction<SuccessModalState>) => {
            state.image = action.payload.image;
            state.title = action.payload.title;
            state.message = action.payload.message;
            state.withButton = action.payload.withButton;
            state.buttonLink = action.payload.buttonLink;
            state.buttonTitle = action.payload.buttonTitle;
            state.isShow = action.payload.isShow;
            state.origin = action.payload.origin;
            state.onCancel = action.payload.onCancel;
            state.onButtonClick = action.payload.onButtonClick;
        },
        reset: (state: SuccessModalState) => {
            state.image = '';
            state.title = '';
            state.message = '';
            state.withButton = false;
            state.buttonLink = '';
            state.buttonTitle = '';
            state.isShow = false;
            state.origin = '';
            state.onCancel = undefined;
            state.onButtonClick = undefined;
        },
        closeSuccessModal: (state: SuccessModalState) => {
            state.isShow = false;
        },
    },
});

export const successModalSelector = (state: RootState) => state.successModal;
export const { setSuccessState, reset, closeSuccessModal } = successModalSlice.actions;
