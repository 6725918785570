import { getAxiosClientWithToken } from 'api';
import {
    DepartmentByBranchRequest,
    EmployeeAssignRequest,
    EmployeeDetailRequest,
    FetchingRegisterEmployeeRequest,
    GetBranchRequest,
    ReassignCompanyEmployeeRequest,
    RegisterEmployeeFormData,
    RegisterEmployeeRequest,
    TransferEmployeeRequest,
    UpdateEmployeeBySuperAdminRequest,
    UpdateEmployeeRequest
} from 'types';

export const getEmployeesDetail = ({ employeeCode }: EmployeeDetailRequest) => {
    const restClient = getAxiosClientWithToken();

    return restClient.get(`/daido/employees/${employeeCode}`);
};

export const updateEmployee = (params: UpdateEmployeeRequest) => {
    const restClient = getAxiosClientWithToken();

    return restClient.put(`/daido/employees/${params.current_employee_code}`, params);
};

export const updateEmployeeBySuperAdmin = (params : UpdateEmployeeBySuperAdminRequest) => {
    const restClient = getAxiosClientWithToken();

    return restClient.put(`/daido/employee/${params.current_employee_code}/edit`, params);
};

export const getBranches = (params?: GetBranchRequest) => {
    const restClient = getAxiosClientWithToken();

    return restClient.get('/daido/branches', {params});
};

export const getDepartmentByBranch = ({branchCode, departmentCode} : DepartmentByBranchRequest) => {
    const restClient = getAxiosClientWithToken();

    return restClient.get(`/daido/departments/branches/${branchCode}`, {params: {department_code: departmentCode}});
};

export const transferBranchEmployee = ({employeeCode, branchCode, departmentCode} : TransferEmployeeRequest) => {
    const restClient = getAxiosClientWithToken();

    return restClient.put(`/daido/employees/${employeeCode}/transfer-branch`, {branch_code: branchCode, department_code: departmentCode});
};

export const transferBranchEmployeeBySuperAdmin = ({employeeCode, branchCode, departmentCode} : TransferEmployeeRequest) => {
    const restClient = getAxiosClientWithToken();

    return restClient.post(`/daido/employee/${employeeCode}/transfer`, {branch_code: branchCode, department_code: departmentCode});
};

export const deleteEmployee = ({employeeCode, reassignCompanies} : ReassignCompanyEmployeeRequest) => {
    const restClient = getAxiosClientWithToken();

    return restClient.delete(`/daido/employees/${employeeCode}`, {
        data: {reassign_companies: reassignCompanies},
        headers: {
            'Cache-Control': 'no-cache',
        }
    });
};

export const deleteEmployeeBySupperAdmin = ({employeeCode, reassignCompanies} : ReassignCompanyEmployeeRequest) => {
    const restClient = getAxiosClientWithToken();

    return restClient.delete(`/daido/super-admin/employees/${employeeCode}`, {
        data: {reassign_companies: reassignCompanies},
        headers: {
            'Cache-Control': 'no-cache',
        }
    });
};

export const getCompanyOfEmployee = ({employeeCode} : EmployeeDetailRequest) => {
    const restClient = getAxiosClientWithToken();

    return restClient.get(`/daido/employees/${employeeCode}/companies`);
};

export const getCompanyOfEmployeeBySuperAdmin = ({employeeCode} : EmployeeDetailRequest) => {
    const restClient = getAxiosClientWithToken();

    return restClient.get(`/daido/super-admin/employees/${employeeCode}/companies`);
};

export const getEmployeeAssignByEmployeeAndBranch = ({employeeCode, branchCode, keyword}: EmployeeAssignRequest) => {
    const restClient = getAxiosClientWithToken();

    return restClient.get(`/daido/employees/${employeeCode}/branch/${branchCode}`, {params: {keyword}});
};

export const getEmployeeAssignByEmployeeAndBranchBySuperAdmin = ({employeeCode, branchCode, keyword}: EmployeeAssignRequest) => {
    const restClient = getAxiosClientWithToken();

    return restClient.get(`/daido/super-admin/employees/${employeeCode}/branch/${branchCode}`, {params: {keyword}});
};

export const registerEmployee = (params: RegisterEmployeeRequest) => {
    const restClient = getAxiosClientWithToken();

    return restClient.post('/daido/register', params);
};

export const registerEmployeeOneByOne = (params: RegisterEmployeeFormData) => {
    const restClient = getAxiosClientWithToken();

    return restClient.post('/daido/invite-register/employee', params);
};

export const fetchEmployeesByDepartmentCode = (id: number) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get(`/daido/branches/${id}/employees`);
};

export const fetchRegisterEmployeeData = ({uuid, signature, expires}: FetchingRegisterEmployeeRequest) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get(`/daido/employee/registration/${uuid}`, {params: {signature, expires}});
};

